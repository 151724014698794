import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "./../../../../../../hooks/useAxiosPrivate";
import { faAdd, faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faEye, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Keys from "./../../../../../../utils/constants";
import Utils from "./../../../../../../utils";
import Wrapper from './../../../../../../layouts/Admin/Wrapper';
import useAuth from './../../../../../../hooks/useAuth';
import InputField from "./../../../../components/form/InputField";
import TableStd from './../../../../components/ui/TableStd';
import DropdownU from './../../../../components/ui/DropdownU'
import LimitU from "./../../../../components/ui/LimitU";
import PaginationU from './../../../../components/ui/PaginationU';
import DataNotFound from './../../../../components/ui/DataNotFound';
import ToastU from "./../../../../components/ui/ToastU";
import ButtonU from "./../../../../components/ui/ButtonU";
import Filter from "./Filter";
import SortU from "./../../../../components/ui/SortU";
import Delete from "./Delete";
import Config from "./../../../../../../config";

const HigherEducations = () => {
    const { ready } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [higherEducations, setHigherEducations] = useState([]);
    const [higherEducationsCount, setHigherEducationsCount] = useState();
    
    const [query, setQuery] = useState({
        page:1, limit:10, order: [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    });
    const [find, setfind] = useState('');

    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getHigherEducations();
        }
    }, [ready, query, toast]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: {...prev.filter, name: find }}
        });
    }, [find]);
 
    const getHigherEducations = () => {
        axiosPrivate.get('/higher-educations', { params: query }).then((res) => {
            if (res.status == 200) {
                setHigherEducations(res.data.data);
                setHigherEducationsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createNew = () => { navigate('/serce-admin/settings/higher-educations/create') }

    const modelProps = [
        { 
            name: <SortU title={Keys.NAME} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => {
                return(
                    <Link to={'/serce-admin/settings/higher-educations/'+prop.id} className="no-decor">{prop.name}</Link>
                )
            } 
        },
        {
            name: Keys.LOGO,
            value: (prop) => { return(
                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_UNI_LOGOS}${prop.logo}`} className="img-photo-sm" crossOrigin={true}/>
            ) }
        },
        {
            name: Keys.COUNTRY,
            value: (prop) => { return(<>{prop.country.name}</>) }
        },
        {
            name: Keys.PROVINCE,
            value: (prop) => { return(<>{prop.province.name}</>) }
        },
        {
            name: <SortU title={Keys.REGISTRATION_DATE} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => {
                            navigate('/serce-admin/settings/higher-educations/'+prop.id);
                        }
                    },
                    {
                        name:Keys.DELETE,
                        icon: faTrashCan,
                        color: "text-danger",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <Wrapper>
            <div className="main">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-2 bold fg-dark">{Keys.HIGHER_EDUCATIONS}</span>
                    <div className="d-flex align-items-center">
                        <InputField type={"text"} value={find} setValue={setfind} hint={Keys.FIND} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                        <Filter query={query} setQuery={setQuery} />
                        <ButtonU onSubmit={createNew} className={'bg-green-700 text-white'} icon={faAdd} text={Keys.ADD}/>
                    </div>
                </div>
                { higherEducationsCount ?
                    <div className="mt-2">
                        <TableStd modelProps={modelProps} data={higherEducations} count={higherEducationsCount} query={query}/>
                        <div className='d-flex align-items-center justify-content-between'>
                            <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                            <PaginationU filter={query} setFilter={setQuery} count={higherEducationsCount} />
                        </div>
                    </div>
                    : <DataNotFound/>
                }
            </div>
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
};

export default HigherEducations;