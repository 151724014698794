import { useRef, useState } from "react";
import { useNavigate} from 'react-router-dom';
import useAuth from "./../../../hooks/useAuth";
import AxiosHelper from "./../../../api/client/AxiosHelper";
import Keys from "../../../utils/constants";
import icEmail from './../../../assets/svg/ic_email.svg';
import InputLabelIcon from "../../Admin/components/form/InputLabelIcon";

const SendEmailVerification = ({...props}) => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const emailRef = useRef();
    
    const [email, setEmail] = useState(''); 

    const handleSubmitEmail = (event) => {
        event.preventDefault();
        const v1 = Keys.EMAIL_REGEX.test(email);

        if (!v1) {
            props.setToast({ content: Keys.INVALID_ENTRY, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }
        
        const data = { email };
        AxiosHelper.sendEmailVerification(data).then((res) => {
            if (res.status == 200) {
                setAuth({...auth, user: { email }});
                props.setToast({ content: Keys.VERIFY_YOUR_EMAIL, variant: 'primary', title: Keys.SUCCESS, show: true });
                props.setStep(2);
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
                return;
            }
            switch(err.response.status) {
                case 404: props.setToast({ content: Keys.USER_NOT_FOUND, variant: 'warning', title: Keys.NOT_FOUND, show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true }); break;
            }
        });
    }

    return (
        <>
            <span className="text-center fs-2 bold fg-dark mt-2">{Keys.RESET_PASSWORD}</span>
            <span className="text-center font-14 fg-gray mt-2">{Keys.ENTER_EMAIL_FOR_RESTORING_PASSWORD}</span>
            <div className="container col-md-3 mt-4">
                <form onSubmit={handleSubmitEmail} noValidate>
                    <InputLabelIcon 
                        formClass="" inputClass={'col-md-12'}
                        id={'email'} type={'email'} ref={emailRef}
                        value={email} setValue={setEmail}
                        hint={Keys.EMAIL} autoFocus={true} required={true}
                        label={Keys.EMAIL} labelClass={'fg-dark'} icon={icEmail}
                    />
                    <button type="submit" className="button-u bg-dark text-white py-2 col-12 my-3" disabled={(!email)}>
                        {Keys.CONFIRM}
                    </button>
                    <button onClick={() => navigate(-1)} className="button-u fg-dark col-12 my-3">
                        {Keys.BACK}
                    </button>
                </form>
            </div>
        </>
    );
}

export default SendEmailVerification;