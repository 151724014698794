import { useState, useEffect } from "react";
import { useNaviate } from 'react-router-dom';
import useAxiosPrivate from "../../../hooks/client/useAxiosPrivate";
import Keys from "../../../utils/constants";
import Wrapper from '../../../layouts/Client/Wrapper';

const PrivacyPolicy = () => {
    const axiosPrivate = useAxiosPrivate();

    const [privacyPolicy, setPrivacyPolicy] = useState({});
    
    const [query, setQuery] = useState({
        page:1, limit:1, order: [['version', 'DESC']]
    });

    useEffect(() => {
        getContacts();
    }, [query]);

    const getContacts = () => {
        axiosPrivate.get('/privacy-policy', { params: query }).then((res) => {
            if (res.status == 200) {
                setPrivacyPolicy(res.data.data[0]);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <Wrapper>
            <div className="d-flex align-items-center justify-content-between mx-4">
                <span className="font-50 bold fg-dark">{Keys.PRIVACY_POLICY}</span>
            </div>
            <div className="mx-5 mt-4"
                dangerouslySetInnerHTML={{ __html: privacyPolicy?.content }}/>
        </Wrapper>
    );
};

export default PrivacyPolicy;