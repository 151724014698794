const ContactItem = ({...props}) => {
    return (
        <div className="d-flex flex-column align-items-center bg-light rounded-4 my-3 py-3 px-4">
            <div className="d-flex flex-row font-30 medium my-2">
                <span className="fg-dark ms-2">{props.item.country.name}</span>
                <span className="fg-dark ms-2">{props.item.province.name}</span>
            </div>
            <span className="font-20 fg-dark">{props.item.email}</span>
            <span className="font-20 fg-dark">{props.item.phoneNumber}</span>
            <span className="fg-dark mt-2">{props.item.addressLine}</span>
        </div>
    );
}

export default ContactItem;