import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Keys from "./../../../../utils/constants";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

const DataNotFound = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center m-5">
            <FontAwesomeIcon icon={faSquareXmark} className="fs-3 text-secondary"/>
            <span className="fs-4 text-secondary">{Keys.DATA_NOT_FOUND}</span>
        </div>
    );
}

export default DataNotFound;