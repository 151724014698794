import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from './../../hooks/useAuth';
import LeftSideBar from "./LeftSideBar";

const Wrapper = (props) => {
    const navigate = useNavigate();
    const { auth, ready } = useAuth();

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        if (auth.token == undefined || auth.token == 'Unauthorized') {
            navigate('/serce-admin/auth/signin');
        }
    }, [auth, ready]);

    return (
        ready && <div className="d-flex flex-row">
            <LeftSideBar/>
            { props.children }
        </div>
    );
}

export default Wrapper;