import { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({...props}) => {
    const data = props.data;
    const counts = [];
    const labels = [];

    data.map((item) => {
        counts.push(item.eduSubmissionCount);
        labels.push(item.name);
    });

    const series = [{
        name: 'Yokary okuw jaylary boyunca Tabsyryklar',
        data: counts
    }];

    const options = {
        chart: { type: 'bar', height: 380 },
        plotOptions: {
        bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: { position: 'bottom' },
        }
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7', '#6ff2e7'],
        dataLabels: { enabled: true, textAnchor: 'start',
            style: { fontWeight: '300', colors: ['#ffffff'] },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: { enabled: true, top: 1, left: 1, blur: 0.85, color: '#000', opacity: 0.95 }
        },
        stroke: { width: 0, colors: ['#ffffff'] },
        xaxis: { categories: labels },
        yaxis: { labels: { show: false } },
        title: { text: 'Yokary okuw jaylary boyunca Tabsyryklar', align: 'center', floating: true },
        tooltip: { theme: 'dark',
            x: { show: false },
            y: { title: { formatter: function () { return '' } } }
        }
    }

    return (
        <div className="col-md-12">
            <div className="shadow-sm pt-2 pe-2" id="chart">
                <ReactApexChart options={options} series={series} type="bar"/>
            </div>
        </div>
    );
}

export default ApexChart;