import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "./../../../hooks/client/useAxiosPrivate";
import Keys from "./../../../utils/constants";
import Wrapper from './../../../layouts/Client/Wrapper';
import ContactItem from "./components/ContactItem";

const Contacts = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const location = useLocation();
    // Use URLSearchParams to parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    const title = queryParams.get('title');

    const [contacts, setContacts] = useState([]);
    const [contactsCount, setContactsCount] = useState();
    
    const [query, setQuery] = useState({
        page:1, limit:10, order: [['createdAt', 'ASC']],
        filter: { title: title, createdAtLb: '', createdAtUb: '', name: '' }
    });

    useEffect(() => {
        getContacts();
    }, [query]);

    const getContacts = () => {
        axiosPrivate.get('/contacts', { params: query }).then((res) => {
            if (res.status == 200) {
                setContacts(res.data.data);
                setContactsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <Wrapper>
            <div className="d-flex align-items-center justify-content-between mx-4">
                <span className="font-50 bold fg-dark">{Keys.CONTACTS}</span>
                <Link to={"/support"} className="btn bg-green-400 rounded-5 font-20 medium px-4 text-white no-decor">{Keys.SUPPORT}</Link>
            </div>
            { contactsCount &&
                <div className="d-flex flex-column mb-5 mx-4">
                    { contacts.map((item) => { return (<ContactItem item={item}/>) })}
                </div>
            }
        </Wrapper>
    );
};

export default Contacts;