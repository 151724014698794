import useAxiosPrivate from './../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../components/ui/ModalU';
import Keys from "./../../../../../../utils/constants";

const Delete = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;

    const handleSubmit = (event) => {
        event.preventDefault();

        axiosPrivate.delete('/contacts/delete/'+item.id).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={Keys.WARNING}
            actionbtntext={Keys.DELETE}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <div className="d-flex flex-column align-items-center">
                <span className="medium fg-red-300">{Keys.DATA_DELETION_CONFIRMATION}</span>
                <div className="d-flex flex-row mt-2">
                    <span className="medium fg-dark me-2">{Keys.CONTACT}:</span>
                    <span className="fg-dark me-1">{item.email}</span>
                    <span className="fg-dark">({item.phoneNumber})</span>
                </div>
            </div>
        </ModalU>
    );
}

export default Delete