import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/client/useAxiosPrivate";
import Keys from "../../../utils/constants";
import Wrapper from '../../../layouts/Client/Wrapper';

const TermsOfService = () => {
    const axiosPrivate = useAxiosPrivate();

    const [termsOfService, setTermsOfService] = useState({});
    
    const [query, setQuery] = useState({
        page:1, limit:1, order: [['version', 'DESC']]
    });

    useEffect(() => {
        getContacts();
    }, [query]);

    const getContacts = () => {
        axiosPrivate.get('/terms-of-service', { params: query }).then((res) => {
            if (res.status == 200) {
                setTermsOfService(res.data.data[0]);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <Wrapper>
            <div className="d-flex align-items-center justify-content-between mx-4">
                <span className="font-50 bold fg-dark">{Keys.TERMS_OF_SERVICE}</span>
            </div>
            <div className="mx-5 mt-4"
                dangerouslySetInnerHTML={{ __html: termsOfService?.content }}/>
        </Wrapper>
    );
};

export default TermsOfService;