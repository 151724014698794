import { useEffect } from "react";
import { useState } from "react";
import Keys from "./../../../../utils/constants";

const PaginationU = ({...props}) => {
    const [max, setMax] = useState(0);
    const [active, setActive] = useState(0);

    useEffect(() => {
        setMax(Math.ceil(props.count/props.filter.limit));
    }, [props.count, props.filter]);

    useEffect(() => {
        setActive(props.filter.page-1);
    }, [props.filter]);

    const handleFirst = () => {
        if (props.filter.page > 1) props.setFilter({...props.filter, page: 1});
    }

    const handlePrev = () => {
        if (props.filter.page > 1) props.setFilter({...props.filter, page: props.filter.page - 1});
    }

    const handleNext = () => {
        if (props.filter.page < max) props.setFilter({...props.filter, page: props.filter.page + 1});
    }

    const handleLast = () => {
        if (props.filter.page < max) props.setFilter({...props.filter, page: max});
    }

    const PaginationItem = ({...props}) => {
        const inActiveStyle = 'bg-light fg-dark';
        const activeStyle = 'bg-dark text-white';

        const item = props.item;
        const active = item.active;

        return(
            <div className={`rounded-1 border me-1 px-2 hover-cursor d-flex align-items-center ${active ? activeStyle : inActiveStyle}`}
                onClick={props.onClick}>
                <span className="">{item.name}</span>
            </div>
        );
    }

    return (
        <div className={`d-flex flex-row ${props.className}`}>
            <PaginationItem item={{name: '<<'}} onClick={handleFirst}/>
            <PaginationItem item={{name: Keys.BACK}} onClick={handlePrev}/>
            {
                Array.apply(1, Array(max)).map((x, i) => {
                    return (
                        <PaginationItem
                            key={i}
                            item={{active: i==active, name: i+1}}
                            onClick={() => {
                                setActive(i);
                                props.setFilter({...props.filter, page: (i+1)})}
                            }/>
                    );
                })
            }
            <PaginationItem item={{name: Keys.GO}} onClick={handleNext}/>
            <PaginationItem item={{name: '>>'}} onClick={handleLast}/>
        </div>
    );
}

export default PaginationU;