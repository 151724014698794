import { useState, useEffect } from "react";
import useAuth from './../../../../../hooks/useAuth';
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import ToastU from "./../../../components/ui/ToastU";
import Detail from "./Detail";
import Update from "./Update";

const MyProfile = ({...props}) => {
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});

    const [toggleEdit, setToggleEdit] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getUser();
        }
    }, [ready, toast]);

    const getUser = () => {
        setLoading(true);
        axiosPrivate.get('/users/'+auth.user.id).then((res) => {
            if (res.status === 200) {
                setUser(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <div>
            {!toggleEdit ?
            <Detail
                user={user}
                toggleEdit={toggleEdit}
                setToggleEdit={setToggleEdit}
                loading={loading}
                setLoading={setLoading}
                toast={toast}
                setToast={setToast}/>
            :
            <Update
                user={user}
                toggleEdit={toggleEdit}
                setToggleEdit={setToggleEdit}
                loading={loading}
                setLoading={setLoading}
                toast={toast}
                setToast={setToast}/> }
            <ToastU toast={toast} setToast={setToast}/>
        </div>
    )
}

export default MyProfile;