import AxiosHelper from './../api/AxiosHelper';
import useAuth from './useAuth';

const useSignOut = () => {
    const { setAuth, setReady } = useAuth();

    const signOut = () => {
        AxiosHelper.signOut().then((res) => {
            setAuth(prev => {
                return {...prev, token: 'Unauthorized' }
            });
            setReady(false);
        })
    }

    return signOut;
}

export default useSignOut;