const InputOtpCode = ({ index, inputRefs, handleChange, ...props }) => {

    return (
        <div className={`${props.formClass}`}>
            <input
                id={props.id}
                ref={inputRefs.current[index]}
                type={'text'}
                value={props.value}
                onChange={(e) => handleChange(e.target, index)}
                maxLength={1}
                className={`input-field ${props.inputClass}`}
                onFocus={(e) => e.target.select()}
                autoFocus={props.autoFocus}
            />
        </div>
    );
}

export default InputOtpCode;