import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from "./../../../hooks/client/useAxiosPrivate";
import Keys from "./../../../utils/constants";
import Wrapper from './../../../layouts/Client/Wrapper';
import InputLabeled from "./../components/form/InputLabeled";
import InputAreaLabeled from "./../components/form/InputAreaLabeled";
import SelectLabeled from "./../components/form/SelectLabeled";
import ToastU from './../components/ui/ToastU';

const Support = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [fullName, setFullName] = useState('');
    const [content, setContent] = useState('');
    const [countryId, setCountryId] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const getCountries = () => {
        axiosPrivate.get('/countries').then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        })
    }

    const getProvinces = () => {
        const query = { filter: { countryId } };
        axiosPrivate.get('/provinces', { params: query }).then((res) => {
            if (res.status == 200) setProvinces(res.data.data);
        })
    }

    useState(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (countryId) getProvinces();
    }, [countryId]);

    const createSupport = () => {
        const data = { fullName, content, countryId, provinceId, phoneNumber, email}
        axiosPrivate.post('/supports/create', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: Keys.SUCCESS, show: true });
                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            }
        }).catch((e) => { console.log(e); });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createSupport();
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column align-items-center mx-4">
                <span className="font-50 bold fg-dark">{Keys.SUPPORT}</span>
                <form onSubmit={handleSubmit} className="col-md-4 align-items-center">
                    <InputLabeled label={Keys.FULLNAME} type={'text'} value={fullName} setValue={setFullName} hint={Keys.FULLNAME} required/>
                    <InputLabeled label={Keys.PHONE_NUMBER} type={'phone'} value={phoneNumber} setValue={setPhoneNumber} hint={Keys.PHONE_NUMBER} required/>
                    <InputLabeled label={Keys.EMAIL} type={'email'} value={email} setValue={setEmail} hint={Keys.EMAIL}/>
                    <SelectLabeled label={Keys.COUNTRY} value={countryId} setValue={setCountryId} items={countries}/>
                    <SelectLabeled label={Keys.PROVINCE} value={provinceId} setValue={setProvinceId} items={provinces}/>
                    <InputAreaLabeled label={Keys.CONTENT} rows={10} value={content} setValue={setContent} hint={Keys.CONTENT} required/>
                    <button className="btn bg-green-400 rounded-5 px-5 py-2 font-25 text-white medium col-md-12 my-3">{Keys.CONFIRM}</button>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
};

export default Support;