import { useNavigate, Link } from 'react-router-dom';
import { faArrowUpRightFromSquare, faEllipsisV, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountItemCard = ({...props}) => {
    const navigate = useNavigate();
    const item = props.item;

    return (
        <div className="col-md-4">
            <div className={`p-4 shadow mb-4 ${item.bg}`}>
                <div className="d-flex justify-content-between col-md-12">
                    <span className="medium fg-dark font-14">{item.title}</span>
                    <Link to={item.link}>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='fg-gray'/>
                    </Link>
                </div>
                <div className='d-flex flex-row align-items-center fs-2 mt-2'>
                    <FontAwesomeIcon className={item.color} icon={item.icon}/>
                    <span className="medium fg-dark ms-3">{item.count}</span>
                </div>
            </div>
        </div>
    );
}

export default CountItemCard;