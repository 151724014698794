import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../hooks/useAxiosPrivate';
import ModalU from './../components/ui/ModalU';
import Keys from "./../../../utils/constants";
import SelectLabeled from './../components/form/SelectLabeled';

const Update = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [state, setState] = useState('');

    useEffect(() => {
        setState(item.state);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = { state };

        axiosPrivate.put('/cargos/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={Keys.CARGO}
            actionbtntext={Keys.UPDATE}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <SelectLabeled label={Keys.STATE} type={'text'} items={Keys.ENUM_CARGO_STATE} value={state} setValue={setState} hint={Keys.STATE}/>
            </form>
        </ModalU>
    );
}

export default Update