import { useEffect, useState } from "react";
import Keys from "./../../../../utils/constants";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import ButtonU from "./../../components/ui/ButtonU";
import { useNavigate } from "react-router-dom";

const Detail = ({...props}) => {
    const navigate = useNavigate();

    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    return (
        <div className="bg-light py-2 px-3 rounded-2 border">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{user.fullName}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => navigate(`/serce-admin/users/${user.id}/required-documents`)} className={'bg-gray-50 bg-gradient border-dark fg-blue-700'} text={Keys.REQUIRED_DOCUMENTS}/>
                    <ButtonU onSubmit={() => props.setToggleEdit(true)} className={'bg-blue-700 text-white'} text={Keys.EDIT} icon={faEdit}/>
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="d-flex flex-row m-3">
                <div className="d-flex flex-column me-5 fg-dark font-16">
                    <span className="mb-1">{Keys.FIRSTNAME}:</span>
                    <span className="mb-1">{Keys.LASTNAME}:</span>
                    <span className="mb-1">{Keys.USERNAME}:</span>
                    <span className="mb-1">{Keys.PHONE_NUMBER}:</span>
                    <span className="mb-1">{Keys.VERIFIED}:</span>
                </div>  
                <div className="d-flex flex-column fg-dark font-16 medium">
                    <span className="mb-1">{user.firstName}</span>
                    <span className="mb-1">{user.lastName}</span>
                    <span className="mb-1">{user.email}</span>
                    <span className="mb-1">{user.phoneNumber ? user.phoneNumber : '-'}</span>
                    <span className="mb-1">{user.userAuth && user.userAuth.verified ? Keys.YES : Keys.NO}</span>
                </div>            
            </div>
            <hr />
            {user.userHigherEducation && <div className="d-flex flex-row m-3">
                <div className="d-flex flex-column me-5 fg-dark font-16">
                    <span className="mb-1">{Keys.HIGHER_EDUCATION}:</span>
                    <span className="mb-1">{Keys.GRADE}:</span>
                </div>  
                <div className="d-flex flex-column fg-dark font-16 medium">
                    <span className="mb-1">{user.userHigherEducation.higherEducation.name}</span>
                    <span className="mb-1">{user.userHigherEducation.grade}</span>
                </div>            
            </div>}
        </div>
    );
}

export default Detail;