import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import useAxiosPrivate from './../../../hooks/useAxiosPrivate';
import Keys from "./../../../utils/constants";
import Config from ".././../../config";
import InputLabeled from './../components/form/InputLabeled';
import Wrapper from "./../../../layouts/Admin/Wrapper";
import ButtonU from "./../components/ui/ButtonU";
import ToastU from './../components/ui/ToastU';
import InputAreaLabeled from "./../components/form/InputAreaLabeled";

const NewsCreate = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate()

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [file, setFile] = useState();

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleFile = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const v1 = Keys.NAME_REGEX.test(title);
        if (!v1) {
            setToast({ content: Keys.INVALID_ENTRY, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { 
            title, content,
            path: Config.PATH_NEWS_IMAGES, 
            file };

        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.post('/news/create', data, {headers}).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: Keys.SUCCESS, show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <Wrapper>
            <div className="main">
                <div className="d-flex flex-align-items-center justify-content-between">
                    <span className="fs-2 bold fg-dark">{Keys.NEW} {Keys.NEWS}</span>
                    <div className="d-flex align-items-center">
                        <ButtonU onSubmit={handleSubmit} className={'bg-green-700 text-white'} icon={faSave} text={Keys.SAVE}/>
                    </div>
                </div>
                <form className="row d-flex flex-row m-3 card-8-outline p-3">
                    <div className="col-md-6">
                        <InputLabeled label={Keys.TITLE} type={'text'} value={title} setValue={setTitle} hint={Keys.TITLE}/>
                        <InputAreaLabeled label={Keys.DEFINITION} rows={20} value={content} setValue={setContent} hint={Keys.DEFINITION}/>
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                        <div className="col-md-12 d-flex justify-content-center bg-gray py-2">
                            <img src={image} className="img-news"/>
                        </div>
                        <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn mt-3">
                            {Keys.IMAGE} {Keys.UPLOAD}
                            <input id="uploadBtn" type="file" hidden/>
                        </label>
                    </div>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default NewsCreate