import ill1 from './../../../../assets/svg/illustrations/ill1.svg';

const Card1 = () => {
    return (
        <div className="d-flex flex-column align-items-center rounded-4 bg-sp-primary col-12 py-4 mx-2 mb-4 mt-2">
            <div className="d-flex flex-row align-items-center bg-white px-4 rounded-5 mx-4 mx-md-5 flex-wrap text-center">
                <span className="fg-sp-primary bold font-40">serçe</span>
                <span className="fg-gray font-40 mx-2 mx-md-3 regular">|</span>
                <span className="fg-dark font-40">tehnologiýalary</span>
            </div>
            <span className="fg-gray-50 font-16 font-md-20 medium mt-2">bilen</span>
            <span className="text-white font-40 font-md-50 medium mt-2 mt-md-0">Dünýäni aç</span>
            <div className="d-flex flex-column align-items-center bg-blue-500a text-white mt-3 px-3 px-md-5 py-3">
                <span className="medium font-18 font-md-20">Salam talyplar!</span>
                <span className="regular font-16 font-md-20 text-center">
                    Daşary Döwletlerde okamak pikiriňiz bilen tolgundyryjy başdan geçirmä başlaýarsyňyz!
                    Tutanýerli bolmak, arzuwlaryňyzy amala aşyrmak üçin her gün bir ädim ýakynlaşdyrar
                </span>
            </div>
            <span className="text-white text-center font-40 font-md-50 medium my-4 mx-3">Okuwa tabşyrmak üçin ädimler</span>
            <div className="d-flex flex-row align-items-center justify-content-center position-relative col-12 mt-3" style={{ height: "350px" }}>
                <div className="position-absolute bg-violet-200 rounded-5 col-10 col-md-8 row-2" style={{ height: '150px' }}></div>
                <div className="position-absolute bg-lime-200 rounded-5 col-8 col-md-6" style={{ height: '250px' }}></div>
                <img className="position-absolute col-6 col-md-4 mb-2" src={ill1} alt="illustration_1" />
            </div>

            <button className='btn btn-light font-18 font-md-20 medium px-3 px-md-4 mt-5 mb-2 rounded-5'>Göz gezdir</button>
        </div>
    );
}

export default Card1;
