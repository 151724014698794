import axios from 'axios';
import Config from './../config';

const baseURL = `${Config.URL_SERVER}/api/admin`;

const instance = axios.create({
    baseURL,
    headers: { Accept: 'application/json'},
    withCredentials: true,
});

class AxiosHelper {

    static async login(data) {
        return await instance.post('/auth/signin', data);
    }

    static async signOut() {
        return await instance.post('/auth/signout');
    }

    static async signUp(data) {
        return await instance.post('/auth/signup', data);
    }

    static async verifyOtp(data) {
        return await instance.post('/auth/verify-otp', data);
    }

    static async refresh() {
        return await instance.get('/auth/refresh');
    }

    static async sendEmailVerification(data) {
        return await instance.post('/auth/verify-email', data);
    }

    static async resetPassword(data) {
        return await instance.post('/auth/reset-password', data);
    }

    static async changePassword(data) {
        return await instance.post('/auth/change-password', data);
    }
}

export default AxiosHelper;