import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from './../../../hooks/client/useAxiosPrivate';
import Keys from "../../../utils/constants";
import Config from "../../..//config";
import Wrapper from "../../../layouts/Client/Wrapper";
import useAuth from "../../../hooks/useAuth";

const HigherEducation = () => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();

    const [higherEducation, setHigherEducation] = useState({});

    useEffect(() => {
        getHigherEducation();
    }, []);

    const getHigherEducation = () => {
        axiosPrivate.get('/higher-educations/'+params.id).then((res) => {
            if (res.status == 200) {
                setHigherEducation(res.data);
            }
        })
    }
    
    return (
        <Wrapper>
            <div className="d-flex flex-column mx-4">
                <span className="font-40 bold fg-dark">{higherEducation.name}</span>
                <div className="d-flex flex-row mt-3">
                    <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_UNI_LOGOS}${higherEducation.logo}`} className="img-photo" crossOrigin={true}/>
                    <div className="d-flex flex-column font-18">
                        <div className="d-flex flex-row mx-4">
                            <span className="text-secondary">{Keys.COUNTRY}: </span>
                            <span className="fg-dark ms-2">{higherEducation.country && higherEducation.country.name}</span>
                        </div>
                        <div className="d-flex flex-row mx-4">
                            <span className="text-secondary">{Keys.PROVINCE}: </span>
                            <span className="fg-dark ms-2">{higherEducation.province && higherEducation.province.name}</span>
                        </div>
                        <div className="d-flex flex-column mx-4 mt-3">
                            <span className="text-secondary">{Keys.DEFINITION}: </span>
                            <span className="fg-dark">
                                {higherEducation.description && higherEducation.description.split('\n').map((e => <p>{e}</p>))}
                            </span>
                        </div>
                    </div>
                </div>
                {ready && <div>
                    <span className="mx-4 font-30 medium mt-5">{Keys.REQUIRED_DOCUMENTS}</span>
                    <div className="m-4 bg-light rounded-3 p-4"
                        dangerouslySetInnerHTML={{ __html: higherEducation.country && higherEducation.country.requirements }}/>
                </div>}
            </div>
        </Wrapper>
    );
}

export default HigherEducation