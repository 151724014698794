import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './../../../hooks/useAuth';
import useAxiosPrivate from "./../../../hooks/useAxiosPrivate";
import { faSearch, faEye, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Keys from "./../../../utils/constants";
import Utils from "./../../../utils";
import Wrapper from './../../../layouts/Admin/Wrapper';
import InputField from "./../components/form/InputField";
import TableStd from './../components/ui/TableStd';
import DropdownU from './../components/ui/DropdownU';
import LimitU from "./../components/ui/LimitU";
import PaginationU from './../components/ui/PaginationU';
import DataNotFound from './../components/ui/DataNotFound';
import ToastU from "./../components/ui/ToastU";
import ButtonU from "./../components/ui/ButtonU";
import Filter from "./Filter";
import SortU from "./../components/ui/SortU";
import Delete from "./Delete";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

const Users = () => {
    const navigate = useNavigate();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState();
    const [find, setfind] = useState('');

    const [query, setQuery] = useState({
        page:1, limit:10, order: [['createdAt', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    });

    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getUsers();
        }
    }, [ready, query, toast]);

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find])

    const getUsers = () => {
        axiosPrivate.get('/users', { params: query }).then((res) => {
            if (res.status == 200) {
                setUsers(res.data.data);
                setUsersCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    // const createNew = () => { navigate('/users/create'); }

    const modelProps = [ 
        { 
            name: <SortU title={Keys.FULLNAME} attr={'firstName'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/serce-admin/users/${prop.id}`} className='no-decor'>{prop.fullName}</Link>) } 
        },
        {
            name: <SortU title={Keys.EMAIL} attr={'email'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.email}</>) }
        },
        {
            name: <SortU title={Keys.PHONE_NUMBER} attr={'phoneNumber'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.phoneNumber ? prop.phoneNumber : '-'}</>) }
        },
        {
            name: <SortU title={Keys.REGISTRATION_DATE} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/serce-admin/users/${prop.id}`) },
                    },
                    {
                        name: Keys.DELETE,
                        icon: faTrashCan,
                        color: "text-danger",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <Wrapper>
            <div className="main">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{Keys.USERS}</span>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${Keys.FIND} (${Keys.EMAIL}, ${Keys.NAME})`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    {/* <ButtonU onSubmit={createNew} fg={'fg-green-500'} border={'border-success'} icon={faAdd} text={Keys.ADD}/> */}
                </div>
            </div>
            { usersCount ?
                <div className="mt-2">
                    <TableStd modelProps={modelProps} data={users} count={usersCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={usersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            </div>
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
};

export default Users;