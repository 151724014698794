import { faCube, faServer, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ListGroupU from "./../../components/ui/ListGroupU";
import Keys from "./../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Admin = () => {
    const listGroup = [
        { name: Keys.SYSTEM, icon: faServer, link: '/page-not-found' },
        { name: Keys.APP, icon: faCube, link: '/page-not-found' }
    ];

    return (
        <div className="card-16-outline bg-danger-accent m-3 p-3">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-3 medium mb-2">{Keys.SETTINGS}/{Keys.ADMINISTRATOR}</span>
                <span className="badge bg-danger p-2">
                    <FontAwesomeIcon className="me-2" icon={faTriangleExclamation}/>
                    {Keys.SECTION_FOR_ADMIN}
                </span>
            </div>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Admin;