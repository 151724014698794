import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import useAuth from './../../../../../../hooks/useAuth';
import useAxiosPrivate from './../../../../../../hooks/useAxiosPrivate';
import Keys from "./../../../../../../utils/constants";
import Config from "./../../../../../../config";
import InputLabeled from './../../../../components/form/InputLabeled';
import Wrapper from "./../../../../../../layouts/Admin/Wrapper";
import ButtonU from "./../../../../components/ui/ButtonU";
import ToastU from './../../../../components/ui/ToastU';
import InputAreaLabeled from "./../../../../components/form/InputAreaLabeled";
import SelectLabeled from "./../../../../components/form/SelectLabeled";

const HigherEducation = () => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();

    const [higherEducation, setHigherEducation] = useState({});
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [logo, setLogo] = useState('');
    const [file, setFile] = useState();
    const [countryId, setCountryId] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [description, setDescription] = useState('');

    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getHigherEducation();
            getCountries();
        }
    }, [ready, toast]);

    useEffect(() => {
        if (countryId) getProvinces();
    }, [countryId]);

    const getHigherEducation = () => {
        axiosPrivate.get('/higher-educations/'+params.id).then((res) => {
            if (res.status == 200) {
                setHigherEducation(res.data);
                const data = res.data;
                setName(data.name);
                setShortName(data.shortName);
                setCountryId(data.countryId);
                setProvinceId(data.provinceId);
                setDescription(data.description);
            }
        })
    }

    const getCountries = () => {
        axiosPrivate.get('/countries').then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        })
    }

    const getProvinces = () => {
        const query = { filter: { countryId } };
        axiosPrivate.get('/provinces', { params: query }).then((res) => {
            if (res.status == 200) setProvinces(res.data.data);
        });
    }

    const handleFile = (e) => {
        setLogo(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const v1 = Keys.NAME_REGEX.test(name);
        if (!v1) {
            setToast({ content: Keys.INVALID_ENTRY, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { 
            name, countryId, provinceId, description,
            path: Config.PATH_UNI_LOGOS, 
            filename: higherEducation.logo,
            file
        };

        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.put('/higher-educations/update/'+higherEducation.id, data, {headers}).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: Keys.SUCCESS, show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <Wrapper>
            <div className="main">
                <div className="d-flex flex-align-items-center justify-content-between">
                    <span className="fs-2 bold fg-dark">{higherEducation.name}</span>
                    <div className="d-flex align-items-center">
                        <ButtonU onSubmit={handleSubmit} className={'bg-green-700 text-white'} icon={faSave} text={Keys.SAVE}/>
                    </div>
                </div>
                <form className="row m-3 card-8-outline p-3">
                    <div className="col-md-6">
                        <div className="d-flex mb-4">
                            <div className='d-flex flex-column me-4'>
                                { !logo ?
                                    <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_UNI_LOGOS}${higherEducation.logo}`} className="img-photo mt-2" crossOrigin={true}/>
                                    :
                                    <img src={logo} className="img-photo mt-2"/>
                                }
                                <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn mt-3">
                                    {Keys.LOGO} {Keys.UPLOAD}
                                    <input id="uploadBtn" type="file" hidden/>
                                </label>
                            </div>
                            <div className="d-flex flex-column flex-fill">
                                <InputLabeled label={Keys.NAME} type={'text'} value={name} setValue={setName} hint={Keys.NAME}/>
                                <InputLabeled label={Keys.SHORT_NAME} type={'text'} value={shortName} setValue={setShortName} hint={Keys.SHORT_NAME}/>
                                <SelectLabeled label={Keys.COUNTRY} value={countryId} setValue={setCountryId} items={countries}/>
                                <SelectLabeled label={Keys.PROVINCE} value={provinceId} setValue={setProvinceId} items={provinces}/>
                            </div>
                        </div>
                        <InputAreaLabeled label={Keys.DEFINITION} rows={15} value={description} setValue={setDescription} hint={Keys.DEFINITION}/>
                    </div>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default HigherEducation