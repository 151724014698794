const FoundUsers = ({...props}) => {
    const items = props.items;

    return (
        <ul className='list-group stripped'>
            { items.map((item) => {
                return (
                    <li onClick={() => { props.setItem(item); }}
                        className='list-group-item list-group-item-action d-flex flex-column sm m-0 px-2 py-0 hover-cursor' key={item.id}>
                        <span className="font-12 text-primary">{item.fullName}</span>
                        <span className="small medium text-dark">{item.email}</span>
                    </li>
                )
            })}
        </ul>
    );
}

export default FoundUsers;