import { faSquareCaretRight } from "@fortawesome/free-regular-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const ListGroupU = ({...props}) => {
    return (
        <ListGroup>
            { props.items.map((item, index) => {
                return(
                    <Link to={item.link} className="d-flex align-items-center fg-dark no-decor" key={index}>
                        <ListGroup.Item className="d-flex align-items-center justify-content-between" action>
                            <div className="d-flex align-items-center">
                                {item.icon && <FontAwesomeIcon icon={item.icon}/>}
                                <span className="ms-3">{item.name}</span>
                            </div>
                            {item.link && <FontAwesomeIcon className="text-secondary" icon={faChevronRight}/>}
                        </ListGroup.Item>
                    </Link>
                )
            }) }
        </ListGroup>
    );
}

export default ListGroupU;