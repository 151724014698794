import Wrapper from './../../../layouts/Client/Wrapper';
import Card1 from './components/Card1';
import Card2 from './components/Card2';
import Card3 from './components/Card3';
import Card4 from './components/Card4';
import Card5 from './components/Card5';


const Main = () => {
    return (
        <Wrapper>
            <div className="d-flex flex-column align-items-center px-4 py-2">
                <Card1 />
                <Card2 />
                <Card3 />
                <Card4 />
                <Card5 />
            </div>
        </Wrapper>
    );
}

export default Main;