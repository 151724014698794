import { useState, useEffect } from 'react';
import useAxiosPrivate from './../../../../hooks/useAxiosPrivate';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import Utils from "./../../../../utils";
import Keys from "./../../../../utils/constants";
import InputLabeled from './../../components/form/InputLabeled';
import ButtonU from "./../../components/ui/ButtonU";
import SwitchLabeled from "./../../components/form/SwitchLabeled";

const Update = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();

    const [user, setUser] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verified, setVerified] = useState(false);
    const [admin, setAdmin] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPasswor] = useState('');

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setPhoneNumber(user.phoneNumber);
            setVerified(user.userAuth && user.userAuth.verified);
            setAdmin(user.userAuth && user.userAuth.admin);
        }
    }, [user]);

    const handleSubmit = () => {
        const requiredInputs = [
            { key: firstName, value: Keys.NAME_REQUIRED },
            { key: lastName, value: Keys.LASTNAME_REQUIRED },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { firstName, lastName, phoneNumber };

        axiosPrivate.put('/users/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        }).finally(() => {props.setLoading(false)});
    }

    const handlePrivacyChange = () => {
        const data = { verified, admin };

        axiosPrivate.put('/auth/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        }).finally(() => {props.setLoading(false)});
    }

    const handlePasswordChange = () => {
        const requiredInputs = [
            { key: currentPassword, value: Keys.CURRENT_PASSWORD_REQUIRED },
            { key: newPassword, value: Keys.NEW_PASSWORD_REQUIRED },
            { key: confirmNewPassword, value: Keys.CONFIRM_NEW_PASSWORD_REQUIRED },
            { key: newPassword === confirmNewPassword, value: Keys.MATCH_PASSWORD_INSTRUCTION }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { currentPassword, newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/change-password/'+user.id, data).then((res) => {
            if (res.status == 200) {
                props.setToast({ content: Keys.PASSWORD_UPDATED, variant: 'success', title: Keys.SUCCESS, show: true });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPasswor('');
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        }).finally(() => {props.setLoading(false)});
    }
    
    return (
        <div className='bg-light py-2 px-3 rounded-2 border'>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{user.fullName}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => props.setToggleEdit(false)} className={'bg-dark text-white'} text={Keys.BACK} icon={faChevronLeft}/>
                    <ButtonU onSubmit={handleSubmit} className={`bg-green-700 text-white`} text={Keys.SAVE} icon={faSave}/>
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="m-3">
                <form className="row">
                    <InputLabeled label={Keys.USERNAME} type={'text'} value={email} setValue={setEmail} hint={Keys.USERNAME} className={'col-md-3'} disabled={true}/>
                    <InputLabeled label={Keys.FIRSTNAME} type={'text'} value={firstName} setValue={setFirstName} hint={Keys.FIRSTNAME} className={'col-md-3'} required={true}/>
                    <InputLabeled label={Keys.LASTNAME} type={'text'} value={lastName} setValue={setLastName} hint={Keys.LASTNAME} className={'col-md-3'} required={true}/>
                    <div className='col-md-3'></div>
                    <InputLabeled label={Keys.PHONE_NUMBER} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={Keys.HINT_PHONE_NUMBER} className={'col-md-3'}/>
                    <hr className='my-3'/>
                    <div className='col-md-12 mb-3'>
                        <span className='medium font-18 fg-blue-700'>{Keys.PRIVACY}</span>
                        <span onClick={handlePrivacyChange} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{Keys.UPDATE}</span>
                    </div>
                    <div className='d-flex flex-row'>
                        <SwitchLabeled label={Keys.VERIFIED} value={verified} setValue={setVerified} size="small" className={'col-md-2'} required={false}/>
                        <SwitchLabeled label={Keys.ADMIN} value={admin} setValue={setAdmin} size="small" className={'col-md-2'} required={false}/>
                    </div>

                    <div className='col-md-12 my-3'>
                        <span className='medium font-18 fg-blue-700'>{Keys.SECURITY}</span>
                        <span onClick={handlePasswordChange} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{Keys.UPDATE}</span>
                    </div>
                    <InputLabeled label={`${Keys.CURRENT} ${Keys.PASSWORD}`} type={'password'} value={currentPassword} setValue={setCurrentPassword} className={'col-md-3'} bg={'bg-orange-200'} required={true}/>
                    <InputLabeled label={`${Keys.NEW} ${Keys.PASSWORD}`} type={'password'} value={newPassword} setValue={setNewPassword} className={'col-md-3'} bg={'bg-orange-200'} required={true}/>
                    <InputLabeled label={`${Keys.NEW} ${Keys.PASSWORD} ${Keys.CONFIRM}`} type={'password'} value={confirmNewPassword} setValue={setConfirmNewPasswor} className={'col-md-3'} bg={'bg-orange-200'} required={true}/>
                </form>
            </div>
        </div>
    );
}

export default Update;