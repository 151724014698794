import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import useRefreshToken from './hooks/useRefreshToken';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ClientMain from './pages/Client/Main';
import ClientLogin from './pages/Client/Login/Login';
import ClientSignUp from './pages/Client/Login/SignUp';
import ClientVerifyOtp from './pages/Client/Login/VerifyOtp';
import ClientResetPassword from './pages/Client/Login/ResetPassword';

import ClientNews from './pages/Client/News';
import ClientHigherEducations from './pages/Client/HigherEducations';
import ClientHigherEducation from './pages/Client/HigherEducations/HigherEducation';
import ClientNewsDetail from './pages/Client/News/NewsDetail';
import ClientContacts from './pages/Client/Contacts';
import ClientSupport from './pages/Client/Support';
import ClientTermsOfService from './pages/Client/TermsOfService';
import ClientPrivacyPolicy from './pages/Client/PrivacyPolicy';

import Login from './pages/Admin/Login/Login';
import SignUp from './pages/Admin/Login/SignUp';
import VerifyOtp from './pages/Admin/Login/VerifyOtp';
import ResetPassword from './pages/Admin/Login/ResetPassword';
import Home from './pages/Admin/Home';
import Users from './pages/Admin/Users';
import User from './pages/Admin/Users/User';
import UserDetail from './pages/Admin/Users/User/RequiredDocuments';
import EduSubmissions from './pages/Admin/EduSubmissions';
import Cargos from './pages/Admin/Cargos';
import News from './pages/Admin/News';
import NewsDetail from './pages/Admin/News/NewsDetail';
import NewsCreate from './pages/Admin/News/NewsCreate';
import Banners from './pages/Admin/Settings/Tabs/Common/Banners';
import BannerDetail from './pages/Admin/Settings/Tabs/Common/Banners/BannerDetail';
import BannerCreate from './pages/Admin/Settings/Tabs/Common/Banners/BannerCreate';
import Supports from './pages/Admin/Supports';
import Settings from './pages/Admin/Settings';
import Profile from './pages/Admin/Profile';
import Countries from './pages/Admin/Settings/Tabs/Common/Countries';
import RequiredDocuments from './pages/Admin/Settings/Tabs/Common/Countries/Requirements';
import Provinces from './pages/Admin/Settings/Tabs/Common/Provinces';
import ShipmentTypes from './pages/Admin/Settings/Tabs/Common/ShipmentTypes';
import HigherEducations from './pages/Admin/Settings/Tabs/Common/HigherEducations';
import NewHigherEducation from './pages/Admin/Settings/Tabs/Common/HigherEducations/NewHigherEducation';
import HigherEducation from './pages/Admin/Settings/Tabs/Common/HigherEducations/HigherEducation';
import Contacts from './pages/Admin/Settings/Tabs/Common/Contacts';
import TermsOfService from './pages/Admin/Settings/Tabs/Common/TermsOfService';
import PrivacyPolicy from './pages/Admin/Settings/Tabs/Common/PrivacyPolicy';

import PageNotFound from './pages/PageNotFound';

function App() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    refresh();
  }, [auth.token]);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ClientMain />} />
        <Route path='/auth/signin' element={<ClientLogin />} />
        <Route path='/auth/signup' element={<ClientSignUp />} />
        <Route path='/auth/verify-otp' element={<ClientVerifyOtp/>} />
        <Route path='/auth/reset-password' element={<ClientResetPassword />} />

        <Route path='/news' element={<ClientNews />} />
        <Route path='/news/:id' element={<ClientNewsDetail />} />
        <Route path='/higher-educations' element={<ClientHigherEducations />} />
        <Route path='/higher-educations/:id' element={<ClientHigherEducation />} />
        <Route path='/contact' element={<ClientContacts />} />
        <Route path='/support' element={<ClientSupport />} />
        <Route path='/terms-of-service' element={<ClientTermsOfService />} />
        <Route path='/privacy-policy' element={<ClientPrivacyPolicy />} />

        <Route path='/serce-admin/auth/signin' element={<Login />} />
        <Route path='/serce-admin/auth/signup' element={<SignUp />} />
        <Route path='/serce-admin/auth/verify-otp' element={<VerifyOtp/>} />
        <Route path='/serce-admin/auth/reset-password' element={<ResetPassword />} />
        <Route path='/serce-admin/'element={<Home />} />
        <Route path='/serce-admin/users'element={<Users />} />
        <Route path='/serce-admin/users/:id'element={<User />} />
        <Route path='/serce-admin/users/:id/required-documents'element={<UserDetail />} />
        <Route path='/serce-admin/edu-submissions'element={<EduSubmissions />} />
        <Route path='/serce-admin/cargos'element={<Cargos />} />
        <Route path='/serce-admin/news'element={<News />} />
        <Route path='/serce-admin/news/create'element={<NewsCreate />} />
        <Route path='/serce-admin/news/:id'element={<NewsDetail />} />
        <Route path='/serce-admin/settings/banners'element={<Banners />} />
        <Route path='/serce-admin/settings/banners/create'element={<BannerCreate />} />
        <Route path='/serce-admin/settings/banners/:id'element={<BannerDetail />} />
        <Route path='/serce-admin/supports'element={<Supports />} />
        <Route path='/serce-admin/settings'element={<Settings />} />
        <Route path='/serce-admin/settings/countries'element={<Countries />} />
        <Route path='/serce-admin/settings/countries/:id'element={<Provinces />} />
        <Route path='/serce-admin/settings/countries/:id/required-documents'element={<RequiredDocuments />} />
        <Route path='/serce-admin/settings/shipment-types'element={<ShipmentTypes />} />
        <Route path='/serce-admin/settings/higher-educations'element={<HigherEducations />} />
        <Route path='/serce-admin/settings/higher-educations/create'element={<NewHigherEducation />} />
        <Route path='/serce-admin/settings/higher-educations/:id'element={<HigherEducation />} />
        <Route path='/serce-admin/settings/contacts'element={<Contacts />} />
        <Route path='/serce-admin/settings/terms-of-service'element={<TermsOfService />} />
        <Route path='/serce-admin/settings/privacy-policy'element={<PrivacyPolicy />} />
        <Route path='/serce-admin/profile'element={<Profile />} />
        <Route path='/page-not-found'element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;