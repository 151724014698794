import { Link, useLocation } from "react-router-dom"
import useSignOut from './../../hooks/useSignOut';
import Keys from "./../../utils/constants";
import Config from "./../../config";
import DropdownProfile from './DropdownProfile';
import icLogo from './../../assets/svg/ic_logo_white.svg';
import icHomeLine from './../../assets/svg/ic_home_line.svg';
import icLayersThree from './../../assets/svg/ic_layers_three.svg';
import icCheckDone from './../../assets/svg/ic_check_done.svg';
import icPieChart from './../../assets/svg/ic_pie_chart.svg';
import icUsers from './../../assets/svg/ic_users.svg';
import icAnnouncement from './../../assets/svg/ic_announcement.svg';
import icSupport from './../../assets/svg/ic_support.svg';
import icSettings from './../../assets/svg/ic_settings.svg';
import icSignOut from './../../assets/svg/ic_sign_out.svg';

const LeftSideBar = () => {
    const location = useLocation();
    const signOut = useSignOut();

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[2];
            const path = location.pathname.split('/')[2];
            return (baseLink === path);
        });

        return flag;
    }
    
    const items = [
        { name: Keys.MAIN, icon: icHomeLine, id: "main", links: Keys.MAIN_MENU_LINKS },
        { name: Keys.EDUCATION_SUBMISSIONS, icon: icLayersThree, id: "edu-submissions", links: Keys.EDUCATION_SUBMISSIONS_LINKS },
        { name: Keys.CARGO_REQUESTS, icon: icCheckDone, id: "cargos", links: Keys.CARGO_REQUESTS_MENU_LINKS },
        { name: Keys.USERS, icon: icUsers, id: "users", links: Keys.USERS_MENU_LINKS },
        { name: Keys.NEWS, icon: icAnnouncement, id: "news", links: Keys.NEWS_MENU_LINKS },
        { name: Keys.SUPPORTS, icon: icSupport, id: "supports", links: Keys.SUPPORTS_MENU_LINKS },
    ];


    return (
        <div className="side-bar">
            <div className="">
                <div className="d-flex flex-row align-items-center mb-4">
                    <img src={icLogo} alt="logo" className="wh-45"/>
                    <span className="fs-4 bold fg-yellow-300">{Config.APP_NAME}</span>
                </div>
                <ul className="nav-bar" id="nav-bar">
                    { items.map((item, index)=>{
                        return (
                            <Link to={item.links[0]} className={"nav-item"} key={item.id}>
                                <div className={"nav-link " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                                    <img src={item.icon} alt={item.name} className="icon"/>
                                    <span>{item.name}</span>
                                </div>
                            </Link>
                        );
                    })}
                </ul>
            </div>
            <div>
                <Link to={'/serce-admin/settings'} className="no-decor">
                    <div className="menu-link">
                        <img src={icSettings} alt={Keys.SETTINGS} className="icon"/>
                        <span>{Keys.SETTINGS}</span>
                    </div>
                </Link>
                <hr className="border-white"/>
                <div className="d-flex flex-row align-items-center justify-content-between my-3">
                    <DropdownProfile/>
                    <div onClick={() => {signOut()}}><img src={icSignOut} className="hover-cursor"/></div>
                </div>
            </div>
        </div>
    );
}

export default LeftSideBar;