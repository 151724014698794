import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "./../../../hooks/client/useAxiosPrivate";
import Wrapper from './../../../layouts/Client/Wrapper';
import PaginationU from './../components/ui/PaginationU';
import DataNotFound from './../components/ui/DataNotFound';
import NewsItem from "./components/NewsItem";

const News = () => {
    const axiosPrivate = useAxiosPrivate();

    const [news, setNews] = useState([]);
    const [newsCount, setNewsCount] = useState();
    
    const [query, setQuery] = useState({
        page:1, limit:10, order: [['createdAt', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '', name: '' }
    });

    useEffect(() => {
        getNews();
    }, [query]);

    const getNews = () => {
        axiosPrivate.get('/news', { params: query }).then((res) => {
            if (res.status == 200) {
                setNews(res.data.data);
                setNewsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <Wrapper>
            { newsCount ?
                <div className="container mt-4">
                    <div className="d-flex flex-column">
                        { news.map((item) => { 
                            return (
                                <NewsItem key={item.id} item={item} />
                            );
                        })}
                        <PaginationU 
                            filter={query} 
                            setFilter={setQuery} 
                            count={newsCount} 
                            className="mt-4"
                        />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </Wrapper>
    );
};

export default News;
