import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from "./../../../../config";
import Keys from "./../../../../utils/constants";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const HigherEducationItem = ({ ...props }) => {
    return (
        <div className="d-flex flex-column mt-3 pt-3 px-3 px-md-4">
            <hr className="mb-3 mt-0" />
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between mb-2">
                <span className="font-24 font-md-30 medium fg-dark">{props.item.name}</span>
                <Link to={`/higher-educations/${props.item.id}`}>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fg-blue-500 mt-2 mt-md-0" />
                </Link>
            </div>
            <div className="d-flex flex-column flex-md-row pb-4">
                <img 
                    src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_UNI_LOGOS}${props.item.logo}`} 
                    className="img-fluid mb-3 mb-md-0 col-md-1 col-3" 
                    alt="logo"
                    crossOrigin={true}
                />
                <div className="d-flex flex-column flex-fill ms-md-4">
                    <div className="d-flex flex-column flex-md-row mb-2">
                        <span className="text-secondary font-14 font-md-16">{Keys.COUNTRY}:</span>
                        <span className="fg-dark ms-2 font-14 font-md-16">{props.item.country.name}</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row mb-2">
                        <span className="text-secondary font-14 font-md-16">{Keys.PROVINCE}:</span>
                        <span className="fg-dark ms-2 font-14 font-md-16">{props.item.province.name}</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row">
                        <span className="text-secondary font-14 font-md-16">{Keys.DEFINITION}:</span>
                        <span className="fg-dark ms-2 font-14 font-md-16">
                            {props.item.description.length > 300 ?
                                `${props.item.description.substring(0, 300)}...`
                                : props.item.description
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HigherEducationItem;
