import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/client/useAxiosPrivate";
import Wrapper from '../../../layouts/Client/Wrapper';
import Config from "../../../config";

const NewsDetail = () => {
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();

    const [newsDetail, setNewsDetail] = useState({});

    useEffect(() => {
        getNewsDetail();
    }, []);

    const getNewsDetail = () => {
        axiosPrivate.get('/news/'+params.id).then((res) => {
            if (res.status == 200) {
                setNewsDetail(res.data);
            }
        })
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column align-items-center px-5 py-3">
                <span className="font-40 fg-dark bold text-center mb-2">{newsDetail.title}</span>
                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_NEWS_IMAGES}${newsDetail.image}`} className="col-md-6" crossOrigin={true}/>
                <span className="my-5 mx-5">
                    {newsDetail.content && newsDetail.content.split('\n').map((e => <p>{e}</p>))}
                </span>
            </div>
        </Wrapper>
    );
};

export default NewsDetail;