import Keys from "./../../../../utils/constants";

const FoundHigherEducations = ({...props}) => {
    const items = props.items;

    return (
        <ul className='list-group stripped'>
            { items.map((item) => {
                return (
                    <li onClick={() => { props.setItem(item); }}
                        className='list-group-item list-group-item-action d-flex flex-row align-items-center justify-content-between sm m-0 px-2 py-1 hover-cursor'
                        key={item.id}>
                        <span className="font-12 text-primary">{item.name}</span>
                        <img src={`${Keys.URL_SERVER}/${Keys.PATH_UNI_LOGOS}/${item.logo}`} className="img-photo-sm-x"/>
                    </li>
                )
            })}
        </ul>
    );
}

export default FoundHigherEducations;