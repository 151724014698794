import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from "./../../../../config";
import Utils from "./../../../../utils";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Keys from "../../../../utils/constants";

const NewsItem = ({...props}) => {
    return (
        <div className="d-flex flex-column flex-md-row my-3 py-3 px-4">
            <img 
                src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_NEWS_IMAGES}${props.item.image}`} 
                className="img-fluid mb-3 mb-md-0 col-md-4" 
                alt="news" 
                crossOrigin={true}
            />
            <div className="d-flex flex-column flex-fill mx-md-4">
                <div className="d-flex flex-column">
                    <span className="font-24 font-md-30 bold fg-dark">
                        {props.item.title.length > 90 ?
                            `${props.item.title.substring(0, 90)}...`
                            : props.item.title
                        }
                    </span>
                    <span className="font-16 font-md-20 fg-gray mt-3">
                        {props.item.content.length > 300 ?
                            `${props.item.content.substring(0, 300)}...`
                            : props.item.content
                        }
                    </span>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                    <div>
                        {props.item.content.length > 300
                            && <Link to={'/news/' + props.item.id} className="no-decor medium font-14 font-md-16"><i>{Keys.READ_MORE}</i></Link>
                        }
                    </div>
                    <div className="d-flex flex-row align-items-center fg-gray font-12 font-md-14 mt-2 mt-md-0">
                        <FontAwesomeIcon icon={faCalendar} />
                        <i className="ms-2">{Utils.getTimestampDate(props.item.createdAt)}</i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsItem;
