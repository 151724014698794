import ill3 from './../../../../assets/svg/illustrations/ill3.svg';

const Card3 = () => {
    return (
        <div className="d-flex flex-column align-items-center rounded-4 bg-blue-400 py-4 mx-2 mb-4 mt-2 col-12">
            <span className="text-white text-center font-40 font-md-50 medium my-4 mx-3">Sapaklaryň ýetişigi barada?</span>
            <div className="d-flex flex-row align-items-center justify-content-center position-relative col-12 mt-3" style={{ height: "350px" }}>
                <div className="position-absolute bg-blue-200 rounded-5 col-10 col-md-8" style={{ height: '150px' }}></div>
                <div className="position-absolute bg-pink-100 rounded-5 col-8 col-md-6" style={{ height: '250px' }}></div>
                <img className="position-absolute col-6 col-md-4 mb-2" src={ill3} alt="illustration_1" />
            </div>

            <button className='btn btn-light font-18 font-md-20 medium px-3 px-md-4 mt-5 mb-2 rounded-5'>Göz gezdir</button>
        </div>
    );
}

export default Card3;
