import Keys from "./../../../../utils/constants";

const SelectLabeled = ({...props}) => {

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    return (
        <div className={`d-flex flex-column my-1 ${props.className}`}>
            <div className="d-flex flex-row mb-1">
                <span className="medium small fg-dark me-1">{props.label}</span>
                <span className="medium small fg-red-500">{props.required && '*'}</span>
            </div>
            <select
                size={props.size}
                value={props.value}
                onChange={handleChange}
                className={`rounded-2 border px-2 py-1 small bg-gray-50`}>
                <option value={""} disabled hidden>{Keys.CHOOSE}</option>
                { props.items.map((item) => {
                    return (<option value={item.id} key={item.id}>{item.name}</option>)
                }) }
            </select>
        </div>
    );
}

export default SelectLabeled;