import { faAddressCard, faBuildingColumns, faChalkboard, faFileContract, faGlobe, faTruckRampBox } from "@fortawesome/free-solid-svg-icons";
import ListGroupU from "./../../components/ui/ListGroupU";
import Keys from "./../../../../utils/constants";

const Common = () => {
    const listGroup = [
        { name: Keys.HIGHER_EDUCATIONS, icon: faBuildingColumns, link: '/serce-admin/settings/higher-educations' },
        { name: Keys.COUNTRIES, icon: faGlobe, link: '/serce-admin/settings/countries' },
        { name: Keys.CONTACTS, icon: faAddressCard, link: '/serce-admin/settings/contacts' },
        { name: Keys.SHIPMENT_TYPES_, icon: faTruckRampBox, link: '/serce-admin/settings/shipment-types' },
        { name: Keys.BANNERS, icon: faChalkboard, link: '/serce-admin/settings/banners' },
        { name: Keys.TERMS_OF_SERVICE, icon: faFileContract, link: '/serce-admin/settings/terms-of-service' },
        { name: Keys.PRIVACY_POLICY, icon: faFileContract, link: '/serce-admin/settings/privacy-policy' },
    ];

    return (
        <div className="card-16-outline m-1 p-3">
            <span className="fs-3 medium mb-2">{Keys.SETTINGS}/{Keys.COMMON}</span>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Common;