import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../components/ui/ModalU';
import Keys from "./../../../../../../utils/constants";
import InputLabeled from './../../../../components/form/InputLabeled';

const Update = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [day, setDay] = useState();
    const [order, setOrder] = useState();
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(item.name);
        setDay(item.day);
        setOrder(item.order);
        setDescription(item.description);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = { name, day, order, description };

        axiosPrivate.put('/shipment-types/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={Keys.COUNTRY}
            actionbtntext={Keys.UPDATE}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={Keys.NAME} type={'text'} value={name} setValue={setName} hint={Keys.NAME}/>
                <InputLabeled label={Keys.DAY} type={'number'} value={day} setValue={setDay} hint={Keys.DAY}/>
                <InputLabeled label={Keys.ORDER} type={'number'} value={order} setValue={setOrder} hint={Keys.ORDER}/>
                <InputLabeled label={Keys.DEFINITION} type={'text'} value={description} setValue={setDescription} hint={Keys.DEFINITION}/>
            </form>
        </ModalU>
    );
}

export default Update