import { Link } from "react-router-dom";
import ill5 from './../../../../assets/svg/illustrations/ill5.svg';
import icAppStore from './../../../../assets/svg/icAppStore.svg';
import icGooglePlay from './../../../../assets/svg/icGooglePlay.svg';
import icInstagram from './../../../../assets/svg/icInstagram.svg';
import icTikTok from './../../../../assets/svg/icTikTok.svg';
import icWhatsApp from './../../../../assets/svg/icWhatsApp.svg';
import icTelegram from './../../../../assets/svg/icTelegram.svg';

const Card5 = () => {
    return (
        <div className="d-flex flex-column align-items-center rounded-4 bg-blue-900 py-4 mx-2 mb-4 mt-2 col-12">
            <div className="d-flex flex-column align-items-center bg-blue-500a text-white mt-4 px-3 px-md-5 py-3">
                <span className="medium font-18 font-md-20 text-center">
                    Bilim meýilnamalaryňyzy ugrukdyrmak üçin ajaýyp gural bolan Serçe Bilim programmasyny hödürleyäris
                </span>
                <span className="regular font-18 font-md-20 text-center mt-2">
                    Bu programma hünärmen geňeşçiler bilen aragatnaşyk gurmaga, Uniwersitetler baradaky tazeliklerden habardar bolmak we hünarmenlerimizin tejribelerini paýlaşmaga kömek eder
                </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center position-relative col-12 mt-5" style={{ height: "300px" }}>
                <img className="position-absolute col-8 col-md-4" src={ill5} alt="illustration_1" />
            </div>
            <div className='mt-5 mb-3 d-flex flex-column flex-md-row'>
                <button className='btn mb-2 mb-md-0'>
                    <img src={icAppStore} alt="appstore" />
                </button>
                <button className='btn ml-md-2'>
                    <img src={icGooglePlay} alt="googleplay" />
                </button>
            </div>
            <div className='my-4 d-flex flex-wrap justify-content-center'>
                <a href='https://www.instagram.com/serce_bilim' target='_blank' rel="noreferrer" className='btn mx-2 mb-2'>
                    <img src={icInstagram} alt="instagram" />
                </a>
                <a href='https://www.tiktok.com/@serce_bilim?' target='_blank' rel="noreferrer" className='btn mx-2 mb-2'>
                    <img src={icTikTok} alt="tiktok" />
                </a>
                <a href='https://wa.me/905525743595' target='_blank' rel="noreferrer" className='btn mx-2 mb-2'>
                    <img src={icWhatsApp} alt="whatsapp" />
                </a>
                <a href='https://t.me/serce_bilim' target='_blank' rel="noreferrer" className='btn mx-2 mb-2'>
                    <img src={icTelegram} alt="telegram" />
                </a>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-center mb-2 rounded-2 px-3 py-2 bg-dark">
                <Link to={'/terms-of-service'} className='mx-2 text-light'>Ulanyş Şertleri</Link>
                <span className="text-primary">we</span>
                <Link to={'/privacy-policy'} className='mx-2 text-light'>Gizlinklik Syýasaty</Link>
            </div>
            <span className='font-16 font-md-18 text-light text-center mb-4 mx-3'>Copyright © 2024 • Serçe Bilim • Ähli hukuklary goralan</span>
        </div>
    );
}

export default Card5;
