import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/profileDropdown.css';
import './assets/style/index.css';
import './assets/style/dropdown.css';
import './assets/style/nav-bar.css';
import './assets/style/wh.css';
import './assets/style/colors.css';
import './assets/style/font.css';
import './assets/style/card.css';
import './assets/style/input.css';
import './assets/style/dropdown.css';
import './assets/style/index-client.css';
import './assets/style/nav-bar-client.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App';
import { AuthProvider } from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);