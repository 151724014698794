import Wrapper from './../../../layouts/Admin/Wrapper';
import TabBar from "./../components/ui/TabBar";
import Keys from "./../../../utils/constants";
import MyProfile from "./Tabs/MyProfile";
import Security from "./Tabs/Security";

const Profile = () => {
    const tabs = [
        { eventKey: 'my_profile', title: Keys.MY_PROFILE, content: <MyProfile/> },
        { eventKey: 'security', title: Keys.SECURITY, content: <Security/> }
    ]

    return (
        <Wrapper>
            <div className='main'>
                <TabBar tabs={tabs} className={"m-3"}/>
            </div>
        </Wrapper>
    );
}

export default Profile;