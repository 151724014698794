import { useEffect, useState } from "react";
import useAuth from './../../../hooks/useAuth';
import useAxiosPrivate from './../../../hooks/useAxiosPrivate';
import ModalU from './../components/ui/ModalU';
import Keys from "./../../../utils/constants";
import InputLabeled from './../components/form/InputLabeled';
import FoundUsers from './components/FoundUsers';
import FoundHigherEducations from "./components/FoundHigherEducations";

const Create = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    
    const [email, setEmail] = useState('');
    const [higherEducationName, setHigherEducationName] = useState('');

    const [user, setUser] = useState({});
    const [higherEducation, setHigherEducation] = useState({});

    const [users, setUsers] = useState([]);
    const [higherEducations, setHigherEducations] = useState([]);

    const [queryUser, setQueryUser] = useState({
        limit:0, order: [['createdAt', 'ASC']],
        filter: { name: '' }
    });

    const [queryHigherEducation, setQueryHigherEducation] = useState({
        limit:0, order: [['createdAt', 'ASC']],
        filter: { name: '' }
    });

    useEffect(() => {
        if (ready) {
            getUsers();
        }
    }, [ready, queryUser]);

    
    useEffect(() => {
        if (ready) {
            getHigherEducations();
        }
    }, [ready, queryHigherEducation]);

    useEffect(() => {
        setQueryUser({...queryUser, limit: email ? 3 : 0, filter: { name: email }});
    }, [email]);

    useEffect(() => {
        setQueryHigherEducation({...queryHigherEducation, limit: higherEducationName ? 3 : 0, filter: { name: higherEducationName }});
    }, [higherEducationName]);

    useEffect(() => {
        setEmail(user.email);
        setUsers([]);
    }, [user]);

    useEffect(() => {
        setHigherEducationName(higherEducation.name);
        setHigherEducations([]);
    }, [higherEducation]);

    const getUsers = () => {
        const query = queryUser;
        axiosPrivate.get('/users', { params: query }).then((res) => {
            if (res.status == 200) setUsers(res.data.data);
        });
    }

    const getHigherEducations = () => {
        const query = queryHigherEducation;
        axiosPrivate.get('/higher-educations', { params: query }).then((res) => {
            if (res.status == 200) setHigherEducations(res.data.data);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = { userId: user.id, higherEducationId: higherEducation.id };

        axiosPrivate.post('/edu-submissions/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={Keys.EDUCATION_SUBMISSION}
            actionbtntext={Keys.ADD}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row">
                <div className="col-md-6">
                    <InputLabeled label={Keys.EMAIL} type={'text'} value={email} setValue={setEmail} hint={Keys.EMAIL}/>
                    <FoundUsers items={users} setItem={setUser}/>
                </div>
                <div className="col-md-6">
                    <InputLabeled label={Keys.HIGHER_EDUCATION} type={'text'} value={higherEducationName} setValue={setHigherEducationName} hint={Keys.HIGHER_EDUCATION}/>
                    <FoundHigherEducations items={higherEducations} setItem={setHigherEducation}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create