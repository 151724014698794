import { Modal } from 'react-bootstrap';
import ButtonU from "./ButtonU";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import Keys from './../../../../utils/constants';

const ModalU = ({...props}) => {
    
    const dissmiss = () => {
        props.onHide(false);
    }

    return (
        <Modal {...props} size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-4 medium fg-dark">{props.title}</span>
                    <FontAwesomeIcon className="fs-3 text-secondary hover-cursor" onClick={dissmiss} icon={faWindowClose}/>
                </div>
                <div className="border rounded-2 my-2 p-2">
                    {props.children}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span></span>
                    <div className="d-flex align-items-center mt-2">
                        <ButtonU onSubmit={dissmiss} size={'small'} className={'border-dark'} text={Keys.BACK}/>
                        {props.onSubmit &&
                            <ButtonU onSubmit={props.onSubmit} size={'small'} icon={props.actionbtnicon} text={props.actionbtntext} className={props.actionbtnclassname}/>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalU;