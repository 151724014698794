import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "./../../hooks/useAuth";
import Config from './../../config';
import icLogo from './../../assets/svg/ic_logo.svg';
import Keys from "./../../utils/constants";
import DropdownProfile from '.././../layouts/Client/DropdownProfile';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { ready } = useAuth();

    const items = [
        { name: 'Baş sahypa', id: "main", links: ['/'] },
        { name: 'Täzelikler', id: "news", links: ['/news'] },
        { name: 'Ýokary okuw jaýlary', id: "higher-educations", links: ['/higher-educations'] },
        { name: 'Habarlaşmak', id: "contact", links: ['/contact'] },
    ];

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <div onClick={() => navigate('/')} className='d-flex align-items-center hover-cursor'>
                    <img src={icLogo} alt="logo" className="wh-50 me-2"/>
                    <span className="text-white font-30 bold">{Config.APP_NAME}</span>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse ms-4" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        { items.map((item) => (
                            <li className="nav-item" key={item.id}>
                                <Link to={item.links[0]} className={"nav-link " + (item.links.includes(location.pathname) ? 'active' : '')}>
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {!ready ? (
                        <div className="d-flex">
                            <Link to={'/auth/signup'} className="btn btn-outline-light me-2">{Keys.SIGN_UP}</Link>
                            <Link to={'/auth/signin'} className="btn btn-warning">{Keys.SIGN_IN}</Link>
                        </div>
                    ) : (
                        <DropdownProfile/>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Header;
