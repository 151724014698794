import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "./../../../../../../hooks/useAxiosPrivate";
import { faAdd, faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faEye, faFile, faMap, faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Keys from "./../../../../../../utils/constants";
import Utils from "./../../../../../../utils";
import Wrapper from './../../../../../../layouts/Admin/Wrapper';
import useAuth from './../../../../../../hooks/useAuth';
import InputField from "./../../../../components/form/InputField";
import TableStd from './../../../../components/ui/TableStd';
import DropdownU from './../../../../components/ui/DropdownU'
import LimitU from "./../../../../components/ui/LimitU";
import PaginationU from './../../../../components/ui/PaginationU';
import DataNotFound from './../../../../components/ui/DataNotFound';
import ToastU from "./../../../../components/ui/ToastU";
import ButtonU from "./../../../../components/ui/ButtonU";
import Filter from "./Filter";
import SortU from "./../../../../components/ui/SortU";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";

const Countries = () => {
    const { ready } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [countries, setCountries] = useState([]);
    const [countriesCount, setCountriesCount] = useState();
    const [query, setQuery] = useState({
        page:1, limit:10, order: [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    });
    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getCountries();
        }
    }, [ready, query, toast]);

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const getCountries = () => {
        axiosPrivate.get('/countries', { params: query }).then((res) => {
            if (res.status == 200) {
                setCountries(res.data.data);
                setCountriesCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createNew = () => { setModalCreate(true); }

    const modelProps = [
        { 
            name: <SortU title={Keys.NAME} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(
                <Link to={`/serce-admin/settings/countries/${prop.id}`} className="no-decor">{prop.name}</Link>
            ) } 
        },
        {
            name: <SortU title={Keys.CODE} attr={'code'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.code}</>) }
        },
        {
            name: <SortU title={Keys.REGISTRATION_DATE} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: Keys.PROVINCES,
                        icon: faMap,
                        color: "text-dark",
                        onClick: () => {
                            navigate(`/settings/countries/${prop.id}`);
                        }
                    },
                    {
                        name: Keys.REQUIRED_DOCUMENTS,
                        icon: faFile,
                        color: "text-dark",
                        onClick: () => {
                            navigate(`/serce-admin/settings/countries/${prop.id}/required-documents`);
                        }
                    },
                    {
                        name: Keys.EDIT,
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    },
                    {
                        name:Keys.DELETE,
                        icon: faTrashCan,
                        color: "text-danger",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <Wrapper>
            <div className="main">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-2 bold fg-dark">{Keys.COUNTRIES}</span>
                    <div className="d-flex align-items-center">
                        <InputField type={"text"} value={find} setValue={setfind} hint={Keys.FIND} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                        <Filter query={query} setQuery={setQuery} />
                        <ButtonU onSubmit={createNew} className={'bg-green-700 text-white'} icon={faAdd} text={Keys.ADD}/>
                    </div>
                </div>
                { countriesCount ?
                    <div className="mt-2">
                        <TableStd modelProps={modelProps} data={countries} count={countriesCount} query={query}/>
                        <div className='d-flex align-items-center justify-content-between'>
                            <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                            <PaginationU filter={query} setFilter={setQuery} count={countriesCount} />
                        </div>
                    </div>
                    : <DataNotFound/>
                }
            </div>
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
};

export default Countries;