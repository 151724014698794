import Wrapper from './../../../layouts/Admin/Wrapper';
import TabBar from "./../components/ui/TabBar";
import Keys from "./../../../utils/constants";
import Common from "./Tabs/Common";
import Admin from "./Tabs/Admin";

const Settings = () => {
    const tabs = [
        { eventKey: 'common', title: Keys.COMMON, content: <Common/> },
        { eventKey: 'admin', title: Keys.ADMIN, content: <Admin/> }
    ]

    return (
        <Wrapper>
            <div className='main'>
                <TabBar tabs={tabs} className={"m-3"}/>
            </div>
        </Wrapper>
    );
}

export default Settings;