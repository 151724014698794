import React from "react";
import useAuth from "./../../hooks/useAuth";
import useSignOut from './../../hooks/useSignOut';
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { Dropdown } from "react-bootstrap";
import Keys from "./../../utils/constants";

const DropdownProfile = () => {
  const { auth } = useAuth();
  const signOut = useSignOut();
  const navigate = useNavigate();
  
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className="d-flex">
      <FontAwesomeIcon
      icon={faCircleUser}
      className="fs-3 text-white me-3"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}/>
      <div className="d-flex flex-column">
        <span className="font-12 medium text-white">{auth.user.firstName} {auth.user.lastName}</span>
        <span className="font-12 text-white">{Keys.ADMIN}</span>
      </div>
    </div>
  ));

  return (
    <Dropdown className="large">
      <Dropdown.Toggle as={CustomToggle}/>
      <Dropdown.Menu>
        <Dropdown.ItemText className="text-center medium px-3">{auth.user.firstName} {auth.user.lastName}</Dropdown.ItemText>
        <Dropdown.Item onClick={() => {navigate('/serce-admin/profile')}} className="py-1">{Keys.PROFILE}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {signOut()}}>
          <div className="d-flex align-items-center justify-content-between bg-danger bg-gradient rounded-2 py-1 px-3">
            <span className="text-white">{Keys.EXIT}</span>
            <FontAwesomeIcon className='text-white' icon={faArrowRightFromBracket}/>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownProfile;