import Keys from "./../../../../utils/constants";

const State = ({...props}) => {
    const state = Keys.CARGO_STATE.get(props.state);
    let style = '';

    switch (state) {
        case Keys.WAITING: style = 'bg-gray-50 border-secondary text-dark'; break;
        case Keys.PREPARING: style = 'bg-orange-100 border-warning fg-orange-500'; break;
        case Keys.SHIPPED: style = 'bg-blue-100 border-info fg-blue-500'; break;
        case Keys.DELIVERED: style = 'bg-green-100 border-success fg-green-700'; break;
        default: break;
    }

    return (
        <span className={`rounded-2 border px-2 ${style}`}>{state}</span>
    );
}

export default State;