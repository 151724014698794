import { useEffect, useRef, useState } from "react";
import useAuth from "./../../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import AxiosHelper from "./../../../api/AxiosHelper";
import ToastU from "./../components/ui/ToastU";
import Wrapper from "./Wrapper";
import Config from "../../../config";
import Keys from "./../../../utils/constants";
import InputLabelIcon from "../components/form/InputLabelIcon";
import icEmail from './../../../assets/svg/ic_email.svg';
import icUser from './../../../assets/svg/ic_user.svg';
import icLock from './../../../assets/svg/ic_lock.svg';
import icEyeOpen from './../../../assets/svg/ic_eye_open.svg';
import VerifyOtp from "./VerifyOtp";

const SignUp = ({...props}) => {  
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    
    const [step, setStep] = useState(1);
   
    const emailRef = useRef();

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleSubmit = (event) => {
        event.preventDefault();

        const v1 = Keys.EMAIL_REGEX.test(email);
        const v2 = Keys.PASSWORD_REGEX.test(password);
        const v3 = Keys.NAME_REGEX.test(firstName);
        const v4 = Keys.NAME_REGEX.test(lastName);
        const v5 = (password === matchPassword);
    
        
        if (!v1 || !v2 || !v3 || !v4 || !v5) {
            if (!v1) setToast({ content: Keys.EMAIL_INSTRUCTION, variant: 'danger', title: Keys.ERROR, show: true });
            else if (!v2) setToast({ content: Keys.PASSWORD_INSTRUCTION, variant: 'danger', title: Keys.ERROR, show: true });
            else if (!v3 || !v4) setToast({ content: Keys.NAME_INSTRUCTION, variant: 'danger', title: Keys.ERROR, show: true });
            else if (!v5) setToast({ content: Keys.MATCH_PASSWORD_INSTRUCTION, variant: 'danger', title: Keys.ERROR, show: true });
            
            return;
        }

        const data = { email, firstName, lastName, password };
        
        AxiosHelper.signUp(data).then((res) => {
            if (res.status == 200) {
                setAuth({...auth, user: res.data.user})
                setToast({ content: Keys.VERIFY_YOUR_EMAIL, variant: 'primary', title: Keys.SUCCESS, show: true });
                setStep(2);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: Keys.REGISTRATION_FAILED, variant: 'danger', title: Keys.INVALID_ENTRY, show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true }); break;
            }
        });
    }

    return (
        <Wrapper>
            {step == 1 ?
            <>
                <span className="text-center fs-4 medium fg-dark mt-2">{Keys.SIGN_UP}</span>
                <div className="container col-md-3">
                    <form onSubmit={handleSubmit} noValidate>
                        <InputLabelIcon 
                            formClass="my-2" inputClass={'col-md-12'}
                            id={'email'} type={'email'} ref={emailRef}
                            value={email} setValue={setEmail}
                            hint={Keys.EMAIL} autoFocus={true} required={true}
                            label={Keys.EMAIL} labelClass={'fg-dark'} icon={icEmail}
                        />
                        <InputLabelIcon 
                            formClass="my-2" inputClass={'col-md-12'}
                            id={'firstName'} type={'text'}
                            value={firstName} setValue={setFirstName}
                            hint={Keys.FIRSTNAME} autoFocus={true} required={true}
                            label={Keys.FIRSTNAME} labelClass={'fg-dark'} icon={icUser}
                        />
                        <InputLabelIcon 
                            formClass="my-2" inputClass={'col-md-12'}
                            id={'lastName'} type={'text'}
                            value={lastName} setValue={setLastName}
                            hint={Keys.LASTNAME} autoFocus={true} required={true}
                            label={Keys.LASTNAME} labelClass={'fg-dark'} icon={icUser}
                        />
                        <InputLabelIcon 
                            formClass={'my-2'} inputClass={'col-md-12'}
                            id={'password'} type={'password'}
                            value={password} setValue={setPassword}
                            hint={Keys.PASSWORD} autoFocus={true} required={true}
                            label={Keys.PASSWORD} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                        />
                        <InputLabelIcon 
                            formClass={'my-2'} inputClass={'col-md-12'}
                            id={'confirm_password'} type={'password'}
                            value={matchPassword} setValue={setMatchPassword}
                            hint={Keys.CONFIRM_PASSWORD} autoFocus={false} required={true}
                            label={Keys.CONFIRM_PASSWORD} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                        />

                        <button type="submit" className="button-u bg-dark text-white py-2 col-12 my-3">{Keys.SIGN_UP}</button>
                        <div className="d-flex flex-column mt-3">
                            <Link to={`${Config.ADMIN_PREFIX}/auth/signin`} className="medium no-decor col-12 text-center">{Keys.SIGN_IN}</Link>
                        </div>
                    </form>
                </div>
            </>
            : step === 2 ? 
            <VerifyOtp toast={toast} setToast={setToast} setStep={setStep}/>
            :
            <>
                <span className="fg-dark fs-2 bold">{Keys.SUCCESS}</span>
                <div className="d-flex flex-column">
                    <Link to={`${Config.ADMIN_PREFIX}/auth/signin`} className="medium fg-dark no-decor font-14 col-12 text-center">{Keys.SIGN_IN}</Link>
                </div>
            </>
            }
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default SignUp;