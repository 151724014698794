import { useState } from "react";
import useAuth from './../../../../../hooks/useAuth';
import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faSave, faEdit } from '@fortawesome/free-regular-svg-icons';
import Utils from "./../../../../../utils";
import InputLabeled from './../../../components/form/InputLabeled';
import ButtonU from "./../../../components/ui/ButtonU";
import ToastU from "./../../../components/ui/ToastU";
import Keys from './../../../../../utils/constants';

const Password = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPasswor] = useState('');

    const [toggleEdit, setToggleEdit] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handlePasswordChange = () => {
        const requiredInputs = [
            { key: currentPassword, value: Keys.CURRENT_PASSWORD_REQUIRED },
            { key: newPassword, value: Keys.NEW_PASSWORD_REQUIRED },
            { key: confirmNewPassword, value: Keys.CONFIRM_NEW_PASSWORD_REQUIRED },
            { key: newPassword === confirmNewPassword, value: Keys.MATCH_PASSWORD_INSTRUCTION }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { currentPassword, newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/change-password/'+auth.user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: Keys.PASSWORD_UPDATED, variant: 'success', title: Keys.SUCCESS, show: true });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPasswor('');
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        }).finally(() => {setLoading(false)});
    }

    return (
        <div className="bg-light py-2 px-3 rounded-2 border">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{Keys.PASSWORD}</span>
                <div className="d-flex align-items-center">
                    {!toggleEdit && <ButtonU onSubmit={() => setToggleEdit(true)} className={'bg-blue-700 text-white'} text={Keys.EDIT} icon={faEdit}/>}
                    {toggleEdit && <ButtonU onSubmit={() => setToggleEdit(false)} className={'bg-dark text-white'} text={Keys.BACK} icon={faChevronLeft}/>}
                    {toggleEdit && <ButtonU onSubmit={handlePasswordChange} className={`bg-green-700 text-white`} text={Keys.SAVE} icon={faSave}/>}
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="my-3">
                <form className="row">
                    <InputLabeled label={`${Keys.CURRENT} ${Keys.PASSWORD}`} type={'password'} value={currentPassword} setValue={setCurrentPassword} className={'col-md-3'} bg={'bg-orange-200'} required={toggleEdit} disabled={!toggleEdit}/>
                    <InputLabeled label={`${Keys.NEW} ${Keys.PASSWORD}`} type={'password'} value={newPassword} setValue={setNewPassword} className={'col-md-3'} bg={'bg-orange-200'} required={toggleEdit} disabled={!toggleEdit}/>
                    <InputLabeled label={`${Keys.NEW} ${Keys.PASSWORD} ${Keys.CONFIRM}`} type={'password'} value={confirmNewPassword} setValue={setConfirmNewPasswor} className={'col-md-3'} bg={'bg-orange-200'} required={toggleEdit} disabled={!toggleEdit}/>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </div>
    )
}

export default Password;