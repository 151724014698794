import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from './../../../hooks/useAuth';
import useAxiosPrivate from "./../../../hooks/useAxiosPrivate";
import Keys from "./../../../utils/constants";
import Wrapper from './../../../layouts/Admin/Wrapper';
import CountItemCard from "./CountItemCard";
import Chart1 from "./../components/charts/EduSubmissionsByHigherEducations";
import Config from "../../../config";
import { faSchoolCircleCheck, faTruckRampBox, faUser } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
    const navigate = useNavigate();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [usersCount, setUsersCount] = useState({title: Keys.USERS, count: 0, icon: faUser, color: 'fg-blue-300', link: `${Config.ADMIN_PREFIX}/users`});
    const [eduSubmissionsCount, setEduSubmissionsCount] = useState({title: Keys.EDUCATION_SUBMISSIONS, count: 0, icon: faSchoolCircleCheck, color: 'fg-green-300', link: `${Config.ADMIN_PREFIX}/edu-submissions`});
    const [cargoRequestsCount, setCargoRequestCount] = useState({title: Keys.CARGO_REQUESTS, count: 0, icon: faTruckRampBox, color: 'fg-yellow-300', link: `${Config.ADMIN_PREFIX}/cargos`});

    const [eduSubmissionsByHigherEducations, setEduSubmissionsByHigherEducations] = useState([]);

    const [queryUsersCount, setQueryUsersCount] = useState({filter: {someAtr: 'someValue'}});
    const [queryEduSubmissionsCount, setQueryEduSubmissionsCount] = useState({filter: {someAtr: 'someValue'}});
    const [queryCargoRequestsCount, setCargoRequestsCount] = useState({filter: {someAtr: 'someValue'}});

    useEffect(() => {
        if (ready) {
            getUsersCount();
            getEduSubmissionsCount();
            getCargoRequestsCount();
            getEduSubmissionsByHigherEducations();
        }
    }, [ready])

    const getUsersCount = () => {
        axiosPrivate.get('/count/users', {params: queryUsersCount }).then((res) => {
            if (res.status == 200)
                setUsersCount({...usersCount, count: res.data.count});
        });
    }

    const getEduSubmissionsCount = () => {
        axiosPrivate.get('/count/edu-submissions', {params: queryEduSubmissionsCount }).then((res) => {
            if (res.status == 200)
                setEduSubmissionsCount({...eduSubmissionsCount, count: res.data.count});
        });
    }
    
    const getCargoRequestsCount = () => {
        axiosPrivate.get('/count/cargo-requests', {params: queryCargoRequestsCount }).then((res) => {
            if (res.status == 200)
                setCargoRequestCount({...cargoRequestsCount, count: res.data.count});
        });
    }
   
    const getEduSubmissionsByHigherEducations = () => {
        axiosPrivate.post('/higher-educations/by-edu-submissions').then((res) => {
            if (res.status == 200) {
                setEduSubmissionsByHigherEducations(res.data.data);
            }
        });
    }

    return (
        <Wrapper>
            <div className="main p-5">
                <div className="row mb-4">
                    <CountItemCard item={usersCount}/> 
                    <CountItemCard item={eduSubmissionsCount}/>
                    <CountItemCard item={cargoRequestsCount}/>
                </div>
                <Chart1 data={eduSubmissionsByHigherEducations}/>
            </div>
        </Wrapper>
    );
};

export default Home;