import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from './../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../components/ui/ModalU';
import Keys from "./../../../../../../utils/constants";
import InputLabeled from './../../../../components/form/InputLabeled';
import SelectLabeled from "./../../../../components/form/SelectLabeled";
import InputAreaLabeled from "./../../../../components/form/InputAreaLabeled";

const Update = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryId, setCountryId] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [addressLine, setAddressLine] = useState('');

    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        setEmail(item.email);
        setPhoneNumber(item.phoneNumber);
        setCountryId(item.countryId);
        setProvinceId(item.provinceId);
        setAddressLine(item.addressLine);
    }, [item])

    useEffect(() => {
        if (ready) {
            getCountries();
        }
    }, [ready]);

    useEffect(() => {
        if (countryId) getProvinces();
    }, [countryId]);

    const getCountries = () => {
        axiosPrivate.get('/countries').then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        })
    }

    const getProvinces = () => {
        const query = { filter: { countryId } };
        axiosPrivate.get('/provinces', { params: query }).then((res) => {
            if (res.status == 200) setProvinces(res.data.data);
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const v1 = Keys.EMAIL_REGEX.test(email);

        if (!v1) {
            props.setToast({ content: Keys.INVALID_ENTRY, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { email, phoneNumber, countryId, provinceId, addressLine };

        axiosPrivate.put('/contacts/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} size={''} title={Keys.CONTACT}
            actionbtntext={Keys.UPDATE}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
            <InputLabeled label={Keys.EMAIL} type={'text'} value={email} setValue={setEmail} hint={Keys.EMAIL}/>
                <InputLabeled label={Keys.PHONE_NUMBER} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={Keys.PHONE_NUMBER}/>
                <SelectLabeled label={Keys.COUNTRY} value={countryId} setValue={setCountryId} items={countries}/>
                <SelectLabeled label={Keys.PROVINCE} value={provinceId} setValue={setProvinceId} items={provinces}/>
                <InputAreaLabeled label={Keys.ADDRESS} rows={3} type={'text'} value={addressLine} setValue={setAddressLine} hint={Keys.ADDRESS}/>
            </form>
        </ModalU>
    );
}

export default Update