export default class Config {
    static CO_NAME = "Anonymous"
    static APP_NAME = "Serçe Bilim";
    
    static IS_DEV = false;
    static URL_SERVER = this.IS_DEV ? 'http://127.0.0.1:4000' : 'https://sercebilim.com';
    static ADMIN_PREFIX = '/serce-admin'
    static PATH_PREFIX = this.IS_DEV ? '/static' : '/api/static';
    static PATH_UNI_LOGOS = '/images/higher-educations/logo/';
    static PATH_UNI_IMAGES = '/images/higher-educations/photo/';
    static PATH_NEWS_IMAGES = '/images/news/';
    static PATH_BANNERS = '/images/banners/';
}