import { Link } from "react-router-dom";
import Keys from "./../../../utils/constants";
import Config from "./../../../config";

const Wrapper = (props) => {
    return (
        <div>
            <div className="col-12 login-header">
                <Link to={'/serce-admin/auth/signin'} className="fs-3 text-white bold no-decor">{Config.APP_NAME}</Link>
                <h6 className='text-white mb-1'>{Config.CO_NAME}</h6>
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-light">
                { props.children }
            </div>
        </div>
    );
}

export default Wrapper;