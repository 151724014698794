import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "./../../../hooks/client/useAxiosPrivate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Keys from "./../../../utils/constants";
import Wrapper from './../../../layouts/Client/Wrapper';
import InputField from "./../components/form/InputField";
import PaginationU from './../components/ui/PaginationU';
import DataNotFound from './../components/ui/DataNotFound';
import HigherEducationItem from "./components/HigherEducationItem";

const HigherEducations = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [higherEducations, setHigherEducations] = useState([]);
    const [higherEducationsCount, setHigherEducationsCount] = useState();
    
    const [query, setQuery] = useState({
        page:1, limit:10, order: [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    });
    const [find, setfind] = useState('');

    useEffect(() => {
        getHigherEducations();
    }, [query]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: {...prev.filter, name: find }}
        });
    }, [find]);
 
    const getHigherEducations = () => {
        axiosPrivate.get('/higher-educations', { params: query }).then((res) => {
            if (res.status == 200) {
                setHigherEducations(res.data.data);
                setHigherEducationsCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <Wrapper>
            <div className="d-flex align-items-center justify-content-between mx-4 mt-3">
                <span></span>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={Keys.FIND} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                </div>
            </div>
            { higherEducationsCount ?
                <div className="d-flex flex-column mb-5 mx-4">
                    { higherEducations.map((item) => { return (<HigherEducationItem item={item}/>) })}
                    <PaginationU filter={query} setFilter={setQuery} count={higherEducationsCount} />
                </div>
                : <DataNotFound/>
            }
        </Wrapper>
    );
};

export default HigherEducations;