import Header from "./Header";

const Wrapper = (props) => {
    return (
        <div className="">
            <Header/>
            { props.children }
        </div>
    );
}

export default Wrapper;