import ill2 from './../../../../assets/svg/illustrations/ill2.svg';

const Card2 = () => {
    return (
        <div className="d-flex flex-column align-items-center rounded-4 bg-orange-400 py-4 mx-2 mb-4 mt-2 col-12">
            <span className="text-white text-center font-40 font-md-50 medium my-4 mx-3">Wiza üçin gerekli ädimler</span>
            <div className="d-flex flex-row align-items-center justify-content-center position-relative col-12 mt-3" style={{ height: "350px" }}>
                <div className="position-absolute bg-orange-200 rounded-5 col-10 col-md-8" style={{ height: '150px' }}></div>
                <div className="position-absolute bg-yellow-200 rounded-5 col-8 col-md-6" style={{ height: '250px' }}></div>
                <img className="position-absolute col-6 col-md-4 mb-2" src={ill2} alt="illustration_1" />
            </div>

            <button className='btn btn-light font-18 font-md-20 medium px-3 px-md-4 mt-5 mb-2 rounded-5'>Göz gezdir</button>
        </div>
    );
}

export default Card2;
