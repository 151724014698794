import { useState } from "react";
import ToastU from "./../components/ui/ToastU";
import Wrapper from "./Wrapper";
import SendEmailVerification from './SendEmailVerification';
import VerifyOtp from './VerifyOtp';
import SetNewPassword from "./SetNewPassword";

const ResetPassword = ({...props}) => {
    const [step, setStep] = useState(1);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    return (
        <Wrapper>
            {step === 1 ? 
                <SendEmailVerification toast={toast} setToast={setToast} setStep={setStep}/>
                : step === 2 ? 
                <VerifyOtp toast={toast} setToast={setToast} setStep={setStep}/>
                :
                <SetNewPassword toast={toast} setToast={setToast}/>
            }
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default ResetPassword;