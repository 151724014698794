export default class Keys {
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static NAME_REGEX = /^[a-zA-ZäöüňýçşžÄÖÜŇÝÇŞŽ\s]{0,128}$/;
    static PHONE_NUMBER_REGEX = /^[0-9]$/;
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;

    static UNAUTHORIZED = "Unauthorized";

    static SIGN_IN = "Giriş";
    static SIGN_UP = "Hasap aç";
    static HAVE_ACCOUNT = "Hasabyňyz barmy?";
    static HAVE_NOT_ACCOUNT = "Hasabyňyz ýokmy?";
    static USERNAME = "Ulanyjy ady";
    static EMAIL = "Email";
    static PASSWORD = "Açar sözi";
    static CONFIRM_PASSWORD = "Açar sözi gaýtala";
    static YOUR_USERNAME = "Ulanyjy adyňyz";
    static YOUR_PASSWORD = "Açar sözüňiz";
    static FORGOT_PASSWORD = "Açar sözüni unutdym";
    static RESET_PASSWORD = "Açar sözüni täzele";
    static REMAINING_TIME = "Galan wagt:";
    static RESEND_CODE = "Täze kod iber";
    static SUBMIT = "Tassykla";
    static OTP_CODE = "OTP Kod";
    static X_DIGIT_CODE = "6 sanly kod belgisi";
    static ERR_EMPTY_LOGIN_FIELD = "Ulanyjy ady we Açar sözi girizilmedik";
    static ERR_EMPTY_OTP_FIELD = "Kod belgisi girizilmedik";
    static USERNAME_INSTRUCTION = "6-32 aralygynda simwol. Harp bilen başlamaly. Harplar, sanlar, aşagy çyzgy, we kese çyzgy rugsat berilen.";
    static EMAIL_INSTRUCTION = 'Girizilen E-poçta sintaksisi ýalňyş';
    static PASSWORD_INSTRUCTION = "8-256 aralygynda simwol. Içinde Baş we setir harplar, san we ýörite simwollar bolmaly. Rugsat berilen ýörite simwollar: !@#$%&";
    static MATCH_PASSWORD_INSTRUCTION = "Açar sözi - tassyklanan Açar sözi bilen gabat gelmeli."
    static NAME_INSTRUCTION = "2-32 aralygynda harp. Harplar rugsat berilen"
    static PHONE_NUMBER_INSTRUCTION = "8 sandan ybarat bolup, 6-lyk sany bilen başlamaly";
    static OTP_INSTRUCTION = "Kod sanlardan ybarat bolmaly";
    static VERIFY_YOUR_EMAIL = 'E-poçtaňyzy tassyklaň';
    static INVALID_ENTRY = "Ýalňyş girizilen maglumatlar";
    static NOW = "Şu wagt";
    static ERROR = "Ýalňyşlyk ýüze çykdy";
    static SUCCESS = "Üstünlikli";
    static NO_SERVER_RESPONSE = "Serwerden jogap gelmedi";
    static USERNAME_TAKEN = "Girizen ulanyjy adyňyz öňden alynan";
    static REGISTRATION_FAILED = "Hasap açmakda şowsyz";
    static SIGN_IN_FAILED = "Giriş şowsuz"
    static USER_NOT_FOUND = "Ulanyjy tapylmady"
    static USER_IS_NOT_ADMIN = "Ulanyjy admin däl"
    static UNSUCCESS = "Şowsuz"
    static NOT_FOUND = "Tapylmady"
    static NOT_ALLOWED = "Rugsat berilmedik"
    static ENTER_EMAIL_FOR_RESTORING_PASSWORD = "Açar sözüni dikeltmek üçin E-poçtaňyzy giriziň"
    static EXPIRED_TIME = "Möhleti doldy"
    static UNAUTHOURIZED = "Ulanyjy kimliklendirilmedik"
    static NEW_PASSWORD = "Täze açar sözi"
    static ENTER_VERIF_CODE_THAT_SENT_EMAIL = "E-poçta hasabyňyza iberilen kody giriziň"
    static EXPIRED_CODE = "Kodyň möhleti doldy"
    static INCORRECT_CODE_ENTERED = "Ýalňyş girizilen kod"
    static EMAIL_VERIFICATION = "E-poçta tassyklamak"

    static MAIN = "Esasy";
    static MAIN_PAGE = "Baş sahypa";
    static PROFILE = "Profil";
    static SETTINGS = "Sazlamalar";
    static USERS = "Ulanyjylar";

    static COMMON = "Umumy";
    static ADMIN = "Admin";

    static NEXT = "Dowam";
    static BACK = "Yza";
    static DONE = "Tamamlandy";
    static OK = "Bolýar";
    static ADD = "Goş";
    static SENT = "Iberildi";
    static HELP = "Kömek";
    static FIND = "Gözle";
    static EXIT = "Çykyş";
    static DETAIL = "Giňişleýin"
    static SHOW = "Görkez";
    static EDIT = "Düzelt";
    static DELETE = "Poz";
    static COMPLETE = "Tamamla";
    static SAVE = "Ýatda sakla";
    static CANCEL = "Reddet";
    static UPDATE = "Täzele";
    static NAME = "Ady";
    static DATA_NOT_FOUND = "Maglumat tapylmady";
    static WARNING = "Duýduryş";
    static DANGER = "Howply";
    static CONFIRM = "Tassykla";
    static CONFIRMATION = "Tassyklamak";
    static DEFINITION = "Beýany";
    static ACCOUNT = "Hasap";
    static PROCESSES = "Hereketler";
    static CREATED_DATE = "Döredilen senesi";
    static LAST_UPDATE = "Soňky düzeldiş";
    static TOTAL = "Jemi";

    static CREATE_USER = "Ulanyjy döret"
    static FIRSTNAME = "Ady";
    static LASTNAME = "Familiýasy";
    static MIDDLENAME = "Atasynyň ady";
    static FULLNAME = "Ady Familiýasy";
    static PHONE_NUMBER = "Telefon belgisi";
    static YOUR_FIRSTNAME = "Adyňyz";
    static YOUR_LASTNAME = "Familiýaňyz";
    static YOUR_MIDDLENAME = "Ataňyzyň ady";
    static YOUR_PHONE_NUMBER = "Telefon belgiňiz";
    static REGISTRATION_DATE = "Registrasiýa senesi";
    static STATUS = "Status";
    static ACTIVE = "Işjeň";
    static INACTIVE = "Işjeň däl";
    static CHOOSE = "Saýla";
    static ALL = "Ählisi";
    static ROLE = "Roly";
    static CONTACT = "Kontakt";
    static ADDRESS = "Salgysy";
    static PROVINCE = "Welaýat";
    static DISTRICT = "Şäher/Etrap";

    static CONTINUE = "Dowam etýär";
    static CANCELLED = "Goý bolsun edildi";
    static REJECTED = "Ret edildi";
    static ACCEPTED = "Kabul edildi";

    static DELIVERY = "Eltip bermek";
    static CARGO = "Kargo";
    static DELIVERY_DESCRIPTION = "Merkez ofislerimize eltip bermek";
    static CARGO_DESCRIPTION = "Merkez ofislerimize kargo bilen ibermek";
    static STANDARD = "Standart";
    static EXPRESS = "Ekspres";
    static WAITING = "Garaşylýar";
    static PREPARING = "Taýýarlanylýar";
    static SHIPPED = "Kargo berildi";
    static DELIVERED = "Gowşuryldy";
    static STATISTICS = "Statistikalar";
    static EDUCATION_SUBMISSIONS = "Okuwa tabşyrylanlar";
    static CARGO_REQUESTS = "Kargolar";
    static HIGHER_EDUCATION = "Ýokary Okuw Jaýy";
    static STATE = "Ýagdaýy";
    static DATE = "Senesi";
    static SENDER = "Iberiji";
    static RECEIVER = "Alyjy";
    static NUMBER = "Belgisi";
    static FROM = "Nireden";
    static TO = "Nirä";
    static SHIPMENT_TYPE = "Kargo görnüşi";
    static STANDARD = "Standart";
    static EXPRESS = "Ekspres";
    static DELIVERY_TYPE = "Ofis/Kargo";
    static LOCAL_CARGO = "Ýerli Kargo";
    static FILTER = "Filtr";
    static CLEAR = "Arassala";

    static COUNTRIES = "Ýurtlar";
    static PROVINCES = "Welaýatlar";
    static CONTACTS = "Kontaktlar";
    static HIGHER_EDUCATIONS = "Ýokary Okuw Jaýlary"
    static SHIPMENT_TYPES_ = "Kargo görnüşleri";
    
    static SECTION_FOR_ADMIN = "Administrator ygtyýarlygy bolan ulanyjylar üçin niýetlenen Dolandyryş bölümi!"
    static ADMINISTRATOR = "Administrator";
    static SYSTEM = "Ulgam";
    static APP = "Programma";

    static CODE = "Kody";
    static GO = "Öňe";
    static DATA_DELETION_CONFIRMATION = "Maglumaty pozmagy tassyklaýarsyňyzmy?";
    static COUNTRY = "Ýurt";
    static DAY = "Gün";
    static ORDER = "Tertip No";
    static NEW = "Täze";
    static UPLOAD = "Ýükle";
    static IMAGE = "Surat";
    static DOCUMENT = "Resminama";
    static LOGO = "Logo";
    static SHORT_NAME = "Gysga ady";
    static NEWS = "Täzelikler";
    static BANNERS = "Bannerlar";
    static BANNER = "Banner";
    static TITLE = "Ady";
    static CONTENT = "Mazmuny";
    static SUPPORT = "Goldaw";
    static SUPPORTS = "Goldawlar";
    static EDUCATION_SUBMISSION = "Okuw tabşyrygy";
    static REQUIRED_DOCUMENTS = "Gerekli Resminamalar";

    static CURRENT = "Häzirki";
    static MY_PROFILE = "Profilim";
    static PRIVACY = "Gizlinlik";
    static SECURITY = "Howpsuzlyk";
    static VERIFIED = "Tassyklanan";

    static YES = "Hawa";
    static NO = "Ýok";

    static LASTNAME_REQUIRED = "Familiýa girizilmedik";
    static NAME_REQUIRED = "At girizilmedik";
    static EMAIL_REQUIRED =  "E-poçta girizilmedik";
    static PROVINCE_REQUIRED = "Welaýat saýlanylmadyk";
    static DISTRICT_REQUIRED = "Şäher/Etrap saýlanylmadyk";
    static PHONE_NUMBER_REQUIRED = "Telefon belgisi girizilmedik";
    static CODE_REQUIRED = "Kody girizilmedik";
    static COUNTRY_REQUIRED = "Ýurt saýlanylmadyk";
    static SHORT_NAME_REQUIRED = "Gysga ady girizilmedik";
    static CURRENT_PASSWORD_REQUIRED = "Häzirki açar sözi girizilmedik";
    static NEW_PASSWORD_REQUIRED = "Täze açar sözi girizilmedik";
    static CONFIRM_NEW_PASSWORD_REQUIRED = "Täze açar sözi tassyklamasy girizilmedik";
    static MISMATCH_PASSWORD_REQUIRED = "Täze açar sözi Tassyklanan açar sözi bilen gabat gelenok";
    static PASSWORD_UPDATED = "Açar sözi täzelendi";
    static PASSWORD_REQUIRED = "Açar sözi girizilmedik";
    static PPTC = "Gizlinklik Syýasaty we Ulanyş Şertleri";
    static READ_MORE = "Doly oka";
    static GRADE = "Synpy";

    static VERSION = "Wersiýa";
    static TERMS_OF_SERVICE = "Ulanyş Şertleri"
    static PRIVACY_POLICY = "Gizlinlik Syýasaty"

    static ENUM_SUBMISSION_STATE = [
        { id: 'CONTINUE', name: this.CONTINUE},
        { id: 'CANCELLED', name: this.CANCELLED},
        { id: 'REJECTED', name: this.REJECTED},
        { id: 'ACCEPTED', name: this.ACCEPTED},
    ];

    static ENUM_DELIVERY_TYPE = [
        { id: '0', type: 'DELIVERY', name: this.DELIVERY, description: this.DELIVERY_DESCRIPTION },
        { id: '1', type: 'CARGO', name: this.CARGO, description: this.CARGO_DESCRIPTION },
    ];

    static ENUM_SHIPMENT_TYPE = [
        { id: '0', type: 'STANDARD', name: this.STANDARD },
        { id: '1', type: 'EXPRESS', name: this.EXPRESS },
    ];

    static ENUM_CARGO_STATE = [
        { id: 'WAITING', name: this.WAITING },
        { id: 'PREPARING', name: this.PREPARING },
        { id: 'SHIPPED', name: this.SHIPPED },
        { id: 'DELIVERED', name: this.DELIVERED },
    ];
    
    static SUBMISSION_STATE = new Map([
        ['CONTINUE', this.CONTINUE], ['CANCELLED', this.CANCELLED], ['REJECTED', this.REJECTED], ['ACCEPTED', this.ACCEPTED]
    ]);

    static DELIVERY_TYPES = new Map([
        [ 'DELIVERY', this.DELIVERY ], [ 'CARGO', this.CARGO ],
    ]);

    static SHIPMENT_TYPES = new Map([
        [ 'STANDARD', this.STANDARD ], [ 'EXPRESS', this.EXPRESS ],
    ]);

    static CARGO_STATE = new Map([
        [ 'WAITING', this.WAITING ], [ 'PREPARING', this.PREPARING ], [ 'SHIPPED', this.SHIPPED ], [ 'DELIVERED', this.DELIVERED ],
    ]);

    static MONTHS = new Map([
        ['01', 'Ýanwar'], ['02', 'Fewral'], ['03', 'Mart'], ['04', 'Aprel'], ['05', 'Maý'], ['06', 'Iýun'],
        ['07', 'Iýul'], ['08', 'Awgust'], ['09', 'Sentýabr'], ['10', 'Oktýabr'], ['11', 'Noýabr'], ['12', 'Dekabr'], 
    ]);

    static MONTHS_SHORT = new Map([
        ['01', 'Ýan'], ['02', 'Few'], ['03', 'Mar'], ['04', 'Apr'], ['05', 'Maý'], ['06', 'Iýn'],
        ['07', 'Iýl'], ['08', 'Awg'], ['09', 'Sen'], ['10', 'Okt'], ['11', 'Noý'], ['12', 'Dek'], 
    ]);

    
    // Links --------------------

    static MAIN_MENU_LINKS = ['/serce-admin'];
    static EDUCATION_SUBMISSIONS_LINKS = ['/serce-admin/edu-submissions'];
    static CARGO_REQUESTS_MENU_LINKS = ['/serce-admin/cargos'];
    static USERS_MENU_LINKS = ['/serce-admin/users', '/serce-admin/users/:id', '/serce-admin/users/:id/required-documents'];
    static NEWS_MENU_LINKS = ['/serce-admin/news', '/serce-admin/news/create'];
    static SUPPORTS_MENU_LINKS = ['/serce-admin/supports'];
}