const InputAreaLabeled = ({...props}) => {

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    return (
        <div className={`d-flex flex-column my-1 ${props.className}`}>
            <div className="d-flex flex-row mb-1">
                <span className="medium small fg-dark me-1">{props.label}</span>
                <span className="medium small fg-red-500">{props.required && '*'}</span>
            </div>
            <textarea
                value={props.value}
                onChange={handleChange}
                rows={props.rows}
                className={`rounded-2 border px-2 py-1 small bg-gray-50`}
                placeholder={props.hint}/>
        </div>
    );
}

export default InputAreaLabeled;