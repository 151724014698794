import { useState, useEffect } from "react";
import { useParams } from "react-router";
import useAxiosPrivate from "./../../../../../../hooks/useAxiosPrivate";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Keys from "./../../../../../../utils/constants";
import Wrapper from './../../../../../../layouts/Admin/Wrapper';
import useAuth from './../../../../../../hooks/useAuth';
import ToastU from "./../../../../components/ui/ToastU";
import ButtonU from "./../../../../components/ui/ButtonU";

const Requirements = ({...props}) => {
    const { ready } = useAuth();
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();

    const [country, setCountry] = useState({});

    const [requirements, setRequirements] = useState('');
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'], ['clean'],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#f00f66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],
        ],
    }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'color'
      ]

    useEffect(() => {
        if (ready) {
            getCountry();
        }
    }, [ready, toast]);
 
    const getCountry = () => {
        const id = params.id;
        axiosPrivate.get('/countries/'+id).then((res) => {
            if (res.status == 200) {
                const country = res.data;
                setCountry(country);
                setRequirements(country.requirements ? country.requirements : '');
            }
        }).catch((e) => { console.log(e); });
    }

    const handleSave = () => {
        const id = params.id;
        const data = { requirements };

        axiosPrivate.put('/countries/update/'+id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <Wrapper>
            <div className="main">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-2 bold fg-dark">{country.name}/{Keys.REQUIRED_DOCUMENTS}</span>
                    <div className="d-flex align-items-center">
                        <ButtonU onSubmit={handleSave} className={'bg-green-700 text-white'} icon={faSave} text={Keys.SAVE}/>
                    </div>
                </div>
                <ReactQuill theme="snow" 
                    value={requirements} onChange={setRequirements}
                    modules={modules} formats={formats}/>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default Requirements;