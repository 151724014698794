import Keys from "./../../../../utils/constants";

const State = ({...props}) => {
    const state = Keys.SUBMISSION_STATE.get(props.state);
    let style = '';

    switch (state) {
        case Keys.CONTINUE: style = 'bg-gray-50 border-secondary text-dark'; break;
        case Keys.CANCELLED: style = 'bg-orange-100 border-warning fg-orange-500'; break;
        case Keys.REJECTED: style = 'bg-red-100 border-danger fg-red-500'; break;
        case Keys.ACCEPTED: style = 'bg-green-100 border-success fg-green-700'; break;
        default: break;
    }

    return (
        <span className={`rounded-2 border px-2 ${style}`}>{state}</span>
    );
}

export default State;