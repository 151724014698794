import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "./../../../../../../hooks/useAxiosPrivate";
import { faAdd, faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faEye, faTrashCan} from '@fortawesome/free-regular-svg-icons';
import Keys from "./../../../../../../utils/constants";
import Config from "./../../../../../../config";
import Utils from "./../../../../../../utils";
import Wrapper from './../../../../../../layouts/Admin/Wrapper';
import useAuth from './../../../../../../hooks/useAuth';
import InputField from "./../../../../components/form/InputField";
import TableStd from './../../../../components/ui/TableStd';
import DropdownU from './../../../../components/ui/DropdownU';
import LimitU from "./../../../../components/ui/LimitU";
import PaginationU from './../../../../components/ui/PaginationU';
import DataNotFound from './../../../../components/ui/DataNotFound';
import ToastU from "./../../../../components/ui/ToastU";
import ButtonU from "./../../../../components/ui/ButtonU";
import Filter from "./Filter";
import Delete from "./Delete";
import SortU from "./../../../../components/ui/SortU";

const Banners = () => {
    const navigate = useNavigate();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const location = useLocation();
    // Use URLSearchParams to parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    const title = queryParams.get('title');

    const [banners, setBanners] = useState([]);
    const [bannersCount, setBannersCount] = useState();
    const [find, setfind] = useState(title);

    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [query, setQuery] = useState({
        page:1, limit:10, order: [['createdAt', 'ASC']],
        filter: { title: title, createdAtLb: '', createdAtUb: '' }
    });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getBanners();
        }
    }, [ready, query, toast]);

    useEffect(() => {
        setQuery({...query, filter: { title: find }});
    }, [find])

    const getBanners = () => {
        axiosPrivate.get('/banners', { params: query }).then((res) => {
            if (res.status == 200) {
                setBanners(res.data.data);
                setBannersCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    const createNew = () => { navigate('/serce-admin/settings/banners/create'); }

    const modelProps = [ 
        { 
            name: <SortU title={Keys.TITLE} attr={'title'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/serce-admin/settings/banners/${prop.id}`} className='no-decor'>{prop.title}</Link>) } 
        },
        {
            name: Keys.IMAGE,
            value: (prop) => { return(
                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_BANNERS}${prop.image}`} className="img-news-sm" crossOrigin={true}/>
            ) }
        },
        {
            name: <SortU title={Keys.REGISTRATION_DATE} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDate(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/serce-admin/settings/banners/${prop.id}`) },
                    },
                    {
                        name: Keys.DELETE,
                        icon: faTrashCan,
                        color: "text-danger",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <Wrapper>
            <div className="main">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{Keys.BANNERS}</span>
                <div className="d-flex align-items-center">
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${Keys.FIND} (${Keys.TITLE})`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'bg-green-700 text-white'} icon={faAdd} text={Keys.ADD}/>
                </div>
            </div>
            { bannersCount ?
                <div className="mt-2">
                    <TableStd modelProps={modelProps} data={banners} count={bannersCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={bannersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            </div>
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
};

export default Banners;