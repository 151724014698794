import { useState, useEffect } from 'react';
import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import Utils from "./../../../../../utils";
import Keys from './../../../../../utils/constants';
import InputLabeled from './../../../components/form/InputLabeled';
import ButtonU from "./../../../components/ui/ButtonU";

const Update = ({...props}) => {
    const axiosPrivate = useAxiosPrivate();

    const [user, setUser] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setPhoneNumber(user.phoneNumber);
        }
    }, [user]);

    const handleSubmit = () => {
        const requiredInputs = [
            { key: firstName, value: Keys.NAME_REQUIRED },
            { key: lastName, value: Keys.LASTNAME_REQUIRED},
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: Keys.ERROR, show: true });
            return;
        }

        const data = { firstName, lastName, phoneNumber };

        axiosPrivate.put('/users/update/'+user.id, data).then((res) => {
            if (res.status == 200) {
                props.setToast({ content: Keys.SUCCESS, variant: 'success', title: Keys.SUCCESS, show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <div className='bg-light py-2 px-3 rounded-2 border'>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="fs-2 bold fg-dark">{Keys.MY_PROFILE}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => props.setToggleEdit(false)} className={'bg-dark text-white'} text={Keys.BACK} icon={faChevronLeft}/>
                    <ButtonU onSubmit={handleSubmit} className={`bg-green-700 text-white`} text={Keys.SAVE} icon={faSave}/>
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="m-3">
                <form className="row">
                    <InputLabeled label={Keys.USERNAME} type={'text'} value={email} setValue={setEmail} hint={Keys.USERNAME} className={'col-md-3'} disabled={true}/>
                    <InputLabeled label={Keys.FIRSTNAME} type={'text'} value={firstName} setValue={setFirstName} hint={Keys.FIRSTNAME} className={'col-md-3'} required={true}/>
                    <InputLabeled label={Keys.LASTNAME} type={'text'} value={lastName} setValue={setLastName} hint={Keys.LASTNAME} className={'col-md-3'} required={true}/>
                    <div className='col-md-3'></div>
                    <InputLabeled label={Keys.PHONE_NUMBER} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={Keys.PHONE_NUMBER} className={'col-md-3'}/>
                </form>
            </div>
        </div>
    );
}

export default Update;