import { Link } from "react-router-dom";
import Config from "./../../../config";
import icLogo from "./../../../assets/svg/ic_logo.svg";

const Wrapper = (props) => {
    return (
        <div>
            <div className="col-12 login-header-client">
                <div className="d-flex flex-row align-items-center">
                    <img src={icLogo} alt="sercebilim" className="wh-50"/>
                    <Link to={'/'} className="fs-3 fg-dark bold no-decor">{Config.APP_NAME}</Link>
                </div>
                <h6 className='fg-dark mb-1'>v1.0</h6>
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-gray-50">
                { props.children }
            </div>
        </div>
    );
}

export default Wrapper;