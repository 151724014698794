import { useState, useEffect } from "react";
import useAuth from "./../../../../hooks/useAuth";
import useAxiosPrivate from './../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import ToastU from "./../../components/ui/ToastU";
import Wrapper from './../../../../layouts/Admin/Wrapper';
import Detail from "./Detail";
import Update from "./Update";

const User = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});

    const [toggleEdit, setToggleEdit] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getUser();
        }
    }, [ready, toast]);

    const getUser = () => {
        setLoading(true);
        axiosPrivate.get('/users/'+params.id).then((res) => {
            if (res.status == 200) {
                setUser(res.data);
            }
        }).finally(() => {setLoading(false)});
    }
 
    return (
        <Wrapper loading={loading}>
            <div className="main">
                {!toggleEdit ?
                <Detail
                    user={user}
                    toggleEdit={toggleEdit}
                    setToggleEdit={setToggleEdit}
                    loading={loading}
                    setLoading={setLoading}
                    toast={toast}
                    setToast={setToast}/>
                :
                <Update
                    user={user}
                    toggleEdit={toggleEdit}
                    setToggleEdit={setToggleEdit}
                    loading={loading}
                    setLoading={setLoading}
                    toast={toast}
                    setToast={setToast}/> }
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Wrapper>
    );
}

export default User;